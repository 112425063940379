import { AUTHERROR, HOME, PATH_LIST, UNAUTHORIZED } from 'const';
import { axiosClient, getErrorStatus, pwcLogin } from 'utils';
import { useLocation, useNavigate } from 'react-router';

import { IAuthBody } from 'types';
import React from 'react';

export default function Auth() {
  const location = useLocation();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const iss = urlParams.get('iss');
  const client_id = urlParams.get('client_id');
  const rawState = urlParams.get('state');
  let parsedState = { client_id: '', mode: '' };

  if (rawState) {
    try {
      parsedState = JSON.parse(decodeURIComponent(rawState));
    } catch (error) {
      parsedState = { client_id: '', mode: '' };
    }
  }

  const body = {
    code: code ?? '',
    iss: iss ?? '',
    client_id: client_id ?? '',
    state: parsedState,
  };

  const isPwcLogin = code && iss && client_id;

  React.useEffect(() => {
    if (sessionStorage.getItem('grade')) return navigate(PATH_LIST[HOME]);

    if (isPwcLogin) {
      const postAuth = async (body: IAuthBody) => {
        try {
          const apiClient = await axiosClient('text');
          const res = await apiClient.post('oauth2/callback/', body);
          if (res.status === 200) {
            const userData = res.data;
            sessionStorage.setItem('permissions', JSON.stringify(userData.permissions));
            sessionStorage.setItem('grade', JSON.stringify(userData.grade));
            sessionStorage.setItem('userName', userData.userName);

            const paths = Object.values(PATH_LIST);

            if (
              !paths.includes(userData.redirect_url) ||
              userData.redirect_url === '' ||
              userData.redirect_url === '/'
            ) {
              navigate(PATH_LIST[HOME]);
            } else if (userData.redirect_url.includes('http')) {
              window.location.href = userData.redirect_url;
            } else {
              navigate(userData.redirect_url);
            }
          }
        } catch (e) {
          const status = getErrorStatus(e);
          navigate(PATH_LIST[status === 403 ? UNAUTHORIZED : AUTHERROR]);
        }
      };

      postAuth(body);
    } else {
      pwcLogin(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='w-full h-svh flex items-center justify-center bg-login bg-cover'>
      <section className='text-left text-white max-w-[30%]'>
        <div className='w-14 h-14 mb-5'>
          <img
            src='/images/home/whiteLogo.svg'
            alt='로고'
          />
        </div>
        <h2 className='text-2xl font-semibold mb-5'>Robotic App Portal </h2>
        <p>접근 권한을 확인중입니다. 잠시만 기다려주세요.</p>
      </section>
    </div>
  );
}
