import React from "react";
import { NOTICE, NOTICE_DESCRIPTION } from "const";

//component import
import { PostList } from "components/post";

export default function Notification() {
  return (
    <PostList
      pageName={NOTICE}
      descriptionList={NOTICE_DESCRIPTION}
      page="notice"
    />
  );
}
