import React from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getApplicaions, getFilterList } from "utils";
import { usePagination, usePostListActions, useSelectedPosts } from "store";

//component import
import {
  Filter,
  Pagination,
  Checkbox,
  ValueType,
  Badge,
} from "@appkit4/react-components";
import { PageEndPoint } from "types";
import { TABLE_HEADER_LIST, TABLE_HEADER_LIST_QNA } from "const";
import { TableBase } from "components/display";

export interface FilteredTableProps {
  data: Array<any>;
  canEdit: boolean;
  page: PageEndPoint;
  onTableRowClick?: (id: number) => any;
  buttonGroup?: () => any;
}

export const FilteredTable: React.FC<FilteredTableProps> = ({
  data,
  canEdit = false,
  page,
  buttonGroup,
  onTableRowClick,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  //const
  const buttons = buttonGroup && buttonGroup();
  const tableHeader =
    page === "qna" ? TABLE_HEADER_LIST_QNA : TABLE_HEADER_LIST;
  const tableData =
    data.length === 0 ? [{ id: 1, 번호: "게시글이 없습니다." }] : data;
  const applicaions = getApplicaions();
  const filterList = getFilterList(applicaions);

  //store
  const pagination = usePagination();
  const selectedPosts = useSelectedPosts();
  const { setSelectedPosts, setPagination } = usePostListActions();

  // post list filtering
  const onSelectFilter = (vals: ValueType[]) => {
    searchParams.delete("application");
    const value = vals.join(",");
    searchParams.set("application", value);
    setSearchParams(new URLSearchParams(searchParams.toString()));
  };

  // row click 시 게시글 상세로 이동
  const onClickRow = (id: number) => {
    navigate(`${location.pathname}/${id}`);
    onTableRowClick && onTableRowClick(id);
  };

  //canEdit이 true 경우 첫번째 column에 게시글 삭제를 위한 checkbox 표시
  const renderCheckedCell = (row: any, field: string) => {
    const onCheckboxChange = (value: boolean, event: React.SyntheticEvent) => {
      //prevent event bubbling
      event.stopPropagation();

      //change checkbox status
      if (value) {
        selectedPosts.push(row["번호"]);
      } else {
        selectedPosts.splice(selectedPosts.indexOf(row["번호"]), 1);
      }
      setSelectedPosts([...selectedPosts]);
    };

    if (!(field in row)) return "";

    // qna 게시글 답변 상태 chip view render
    if (field === "상태") {
      return (
        <Badge
          value={row["상태"]}
          type={
            row["상태"] === "답변대기" ? "warning-outlined" : "success-outlined"
          }
        />
      );
    }

    // 관리자 계정의 게시글 다중 삭제를 위한 checkbox render
    return canEdit && field === "번호" && data.length > 0 ? (
      <Checkbox
        value={row[field]}
        checked={selectedPosts.includes(row["번호"])}
        onChange={(value, event) => onCheckboxChange(value, event)}
      >
        <span className="ml-1">{row[field]}</span>
      </Checkbox>
    ) : (
      <span>{row[field]}</span>
    );
  };

  return (
    <>
      <div className="mt-5 relative min-h-12 w-full flex justify-end">
        <div className="absolute top-0 left-0 w-1/6 p-1 border-base bg-white/50 z-30">
          <Filter
            title="Filter"
            multiple={true}
            data={filterList}
            defaultValue={applicaions}
            valueKey={"value"}
            labelKey={"label"}
            filterListClassName="!bg-white/50 !border-gray-300"
            onSelect={onSelectFilter}
          ></Filter>
        </div>
        {buttons && buttons}
      </div>
      {/* 게시물 Table */}
      <TableBase
        headerData={tableHeader}
        originalData={tableData}
        onRowClick={(event: MouseEvent, index: number, data: any) => {
          onClickRow(data["번호"]);
        }}
        selected={selectedPosts}
        setSelected={setSelectedPosts}
        renderCheckedCell={renderCheckedCell}
      />
      <div className="flex justify-center">
        <Pagination
          current={pagination.current_page}
          defaultCurrent={1}
          total={pagination.num_page}
          onPageChange={(page) =>
            setPagination({ ...pagination, current_page: page })
          }
        />
      </div>
    </>
  );
};
