import './App.css';
import '@appkit4/styles/appkit.min.css';
import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

import { AUTH, DSD_WIZARD, FOOTER_CONTENT, FOOTER_LINK_LIST, PATH_LIST, PRODUCT_NAME } from 'const';
import { Avatar, Footer, Header, HeaderOptionItem } from '@appkit4/react-components';
import { Outlet, useLocation, useNavigate } from 'react-router';

import Auth from 'components/pages/auth/Auth';
import ErrorModal from 'components/display/ErrorModal';
import React from 'react';
import { SideNavBar } from './components/display';
import { pwcLogin } from 'utils';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem('grade');
  const userName = sessionStorage.getItem('userName');

  // pwc 로그인
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (!authToken && !code) {
      pwcLogin(location.pathname);
    } else if (!authToken && code) {
      navigate(`${PATH_LIST[AUTH]}${window.location.search}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authToken) {
    return (
      <div className='w-svw h-svh absolute top-0 left-0'>
        <Auth />
      </div>
    );
  }

  return (
    <>
      <div className='fixed top-0 left-0 z-50 w-full'>
        <Header
          type='transparent'
          compact={false}
          titleTemplate={() => PRODUCT_NAME}
          optionsTemplate={() => {
            return (
              <HeaderOptionItem
                iconName='document-multiple-outline'
                label='사용설명서'
                onClick={() => navigate(PATH_LIST[DSD_WIZARD])}
              />
            );
          }}
          userTemplate={() => (
            <Avatar
              label={userName ? userName[0] : 'KR'}
              role='button'
              disabled={false}
              onClick={() => {
                sessionStorage.clear();
                navigate(PATH_LIST[AUTH]);
              }}
            />
          )}
        ></Header>
      </div>
      <div className='flex w-full'>
        <SideNavBar />
        <div className='w-full pb-10 px-5 pt-[4.5rem] xl:px-20'>
          <main className='max-w-full m-auto xl:max-w-[95%] min-h-[78vh]'>
            <Outlet />
            <ErrorModal />
          </main>
          <Footer
            content={FOOTER_CONTENT}
            type='links'
            links={FOOTER_LINK_LIST}
            className='max-w-full xl:max-w-[95%] m-auto mt-6 mb-3'
          />
        </div>
      </div>
    </>
  );
}

export default App;
