import { ACCEPT_FILE_SIZE, ACCEPT_FILE_TYPES } from 'const';
import { Input, Select, Upload } from '@appkit4/react-components';
import { NoticeResponseKeyType, PostDetailType } from 'types';
import { getApplicaions, getFilterList } from 'utils';

import React from 'react';
import StyledTextEditor from 'components/display/StyledTextEditor';
import { UploadList } from 'components/comment';

//component import

export interface PostEditorProps {
  data: PostDetailType;
  onChange: (type: NoticeResponseKeyType, value: any) => void;
}

export interface CustomFile {
  lastModifiedDate: string;
  name: string;
  originFile: File;
  percent: number;
  response: string;
  size: number;
  status: string;
  type: string;
  uid: string;
}

export const PostEditor: React.FC<PostEditorProps> = ({ data, onChange }: PostEditorProps) => {
  const applicaions = getApplicaions();
  const filterList = getFilterList(applicaions);

  // file upload and save at store
  const onChangefile = (file: File, fileList: CustomFile[]): void => {
    const filesArray: File[] = fileList.map((item) => item.originFile);
    onChange('file_path', [...data.file_path, ...filesArray]);
  };

  console.log('data.content', data.content);

  return (
    <form className='mt-7 w-full grid grid-cols-3 gap-4'>
      <Input
        type={'text'}
        title={'제목을 입력해주세요'}
        hideTitleOnInput={data.title === '' ? false : true}
        allowClear={true}
        required={true}
        value={data.title}
        onChange={(value: any) => {
          onChange('title', value);
        }}
        className='col-span-2 '
      ></Input>
      <Select
        hideTitleOnInput={true}
        required={true}
        data={filterList}
        placeholder={'Categories'}
        value={data.application}
        onSelect={(e) => {
          onChange('application', e.toString());
        }}
      ></Select>
      <StyledTextEditor
        data={data.content}
        onChange={(data) => onChange('content', data)}
      />
      <Upload
        onChange={onChangefile}
        multiple={true}
        autoUpload={true}
        showFileList={false}
        acceptFileType={ACCEPT_FILE_TYPES}
        maxFileSize={ACCEPT_FILE_SIZE}
        config={{
          trigger: false,
          type: 'inline',
          size: true,
        }}
        className='col-span-3'
      ></Upload>
      {data.file_path && data.file_path.length > 0 && (
        <>
          <div className='ap-fileupload-footer-delete-attachment'>
            <span
              role='button'
              tabIndex={0}
              className='text-primary-400 font-semibold'
              onClick={() => onChange('file_path', [])}
            >
              Delete all attachments
            </span>
          </div>
          <div className='ap-fileupload col-span-3'>
            <div className='ap-fileupload-wrapper '>
              <UploadList files={data.file_path} />
            </div>
          </div>
        </>
      )}
    </form>
  );
};
