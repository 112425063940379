import { MODAL_CONTENTS } from "const";
import { ModalStatusStoreType } from "types";
import { create } from "zustand";

export const useModalStatus = create<ModalStatusStoreType>((set) => ({
  visible: false,
  variant: "cancelCreate",
  content: MODAL_CONTENTS,
  actions: {
    onClose: () =>
      set(() => ({
        visible: false,
      })),
    onOpen: (variant) =>
      set(() => ({
        visible: true,
        variant: variant,
      })),
  },
}));

export const useModalVisible = () => useModalStatus((state) => state.visible);
export const useModalVariant = () => useModalStatus((state) => state.variant);
export const useModalContent = () => useModalStatus((state) => state.content);
export const useModalActions = () => useModalStatus((state) => state.actions);
