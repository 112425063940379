import { ERROR_MODAL_CONTENT } from 'const';
import { ErrorStoreType } from 'types';
import { create } from 'zustand';

const useErrorStateStore = create<ErrorStoreType>((set) => ({
  error: { status: false, code: 0, message: '' },
  actions: {
    setError: (newError, errorCode) =>
      set(() => ({
        error: { status: newError, code: errorCode, message: ERROR_MODAL_CONTENT[errorCode] },
      })),
  },
}));

export const useErrorStore = () => useErrorStateStore((state) => state.error);
export const useErrorActionsStore = () => useErrorStateStore((state) => state.actions);
