import { DescriptionType } from "types";

//Notice
export const NOTICE_DESCRIPTION: DescriptionType = {
  user: "Application과 관련된 최신 소식, 중요한 업데이트, 이벤트, 그리고 각종 안내 사항을 가장 먼저 확인하실 수 있는 공간입니다. \n공지사항을 통해 새로운 기능 소개, 서비스 변경, 이벤트 참여 방법 등 다양한 정보를 제공해드리고 있습니다. \n항상 최신 정보를 빠르게 받아보실 수 있도록 주기적으로 확인해주세요. 더 나은 서비스를 제공하기 위해 최선을 다하겠습니다.",
  admin:
    "이 페이지에서는 공지사항을 작성, 수정, 삭제할 수 있습니다. 문의사항이 있으시면 담당자에게 연락해 주세요.\n담당자 : 이솜이(somyi.lee@pwc.com)",
  create:
    "Application과 관련된 최신 소식, 중요한 업데이트, 이벤트, 그리고 각종 안내 사항을 작성하여 등록해주세요.",
  edit: "Application과 관련된 최신 소식, 중요한 업데이트, 이벤트, 그리고 각종 안내 사항을 수정하여 등록해주세요.",
};

//Reference
export const REFERENCE_DESCRIPTION: DescriptionType = {
  user: "Application과 관련된 다양한 자료와 문서들을 손쉽게 찾아볼 수 있는 공간입니다. \n필요한 자료를 빠르게 찾고 다운로드할 수 있도록 주기적으로 자료를 업데이트하고 있습니다. \n자료를 찾는 데 도움이 필요하시거나 문의사항이 있으실 경우 Q&A 게시판을 이용해주세요.",
  admin:
    "이 페이지에서는 자료의 업로드, 수정, 삭제할 수 있습니다. \n문의사항이 있으시면 담당자에게 연락해 주세요.\n담당자 : 이솜이(somyi.lee@pwc.com)",
  create: "Application과 관련된 자료를 업로드하여 등록해주세요.",
  edit: "Application과 관련된 자료를 업로드하여 수정해주세요. ",
};

//FAQ
export const FAQ_DESCRIPTION: DescriptionType = {
  user: "자주 묻는 질문과 그에 대한 답변을 확인하실 수 있습니다.\n혹시 찾는 답변이 없거나 추가로 문의할 사항이 있으시면, '문의 게시판'을 통해 직접 문의해주시면 신속히 답변드리겠습니다.",
  admin:
    "이 페이지에서는 FQA 게시글을 업로드, 수정, 삭제할 수 있습니다. \n문의사항이 있으시면 담당자에게 연락해 주세요.\n담당자 : 이솜이(somyi.lee@pwc.com)",
  create: "자주 묻는 질문에 대한 답변을 작성하여 등록해주세요.",
  edit: "자주 묻는 질문에 대한 답변을 수정하여 등록해주세요.",
};

//QNA
export const QNA_DESCRIPTION: DescriptionType = {
  user: "저희 Application와 관련하여 궁금한 점이나 문제가 있는 경우, 언제든지 이 게시판을 통해 문의하실 수 있습니다.\n문의 글을 작성해주시면 최대한 빠른 시간 내에 답변드리겠습니다.\n여러분의 문의에 항상 귀 기울이며, 더 나은 서비스를 제공하기 위해 노력하겠습니다.",
  admin:
    "사용자들이 문의 사항을 남기고 답변을 받을 수 있는 게시판입니다. \n접수된 문의는 신속하게 검토하고 답변해 주세요. \n담당자 : 이솜이(somyi.lee@pwc.com)",
  create:
    "Application와 관련하여 궁금한 점이나 문제를 작성하여 등록해주세요. 최대한 빠른 시간 내에 답변드리겠습니다. ",
};
