import React from "react";

// component import
import {
  toaster,
  Notification,
  NotificationProps,
} from "@appkit4/react-components";

interface useToastPopupProps {
  duration?: number;
  position?: string;
  onClose?: () => void;
}

export function useToastPopup({
  duration = 2000,
  position = "topCenter",
  onClose,
}: useToastPopupProps = {}) {
  const toastRef = React.useRef(null);

  const handleClose = (buttonEle?: any) => {
    buttonEle?.focus();
    onClose && onClose();
  };

  const onOpenPopup = (notificationProps: NotificationProps) => {
    const ele = <Notification {...notificationProps} />;
    toaster.notify(ele, {
      position: position,
      duration: duration,
      onClose: () => handleClose(toastRef.current),
    });
  };

  return { onOpenPopup, toastRef };
}
