import React, { ReactNode } from "react";

export interface PanelHeaderProps {
  title: string;
  description?: string;
  postOption?: ReactNode;
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({
  title,
  description,
  postOption,
}) => {
  const PostInfo = postOption && postOption;
  return (
    <>
      <h3 className="heading-md !font-semibold ml-1">{title}</h3>
      {description && (
        <p className="mt-3 ml-1 body-sm xl:whitespace-pre-wrap !leading-6">
          {description && description}
          {PostInfo && PostInfo}
        </p>
      )}
      {PostInfo && (
        <div className="mt-5 ml-1 body-sm xl:whitespace-pre-wrap !leading-7">
          {PostInfo && PostInfo}
        </div>
      )}
    </>
  );
};
