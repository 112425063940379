/**
 * To ensure proper display of styles, it is essential to import the CSS file after the TextEditor component.
 **/

import '@appkit4/react-text-editor/dist/appkit4-react-texteditor.min.css';

import React from 'react';
import { TextEditor } from '@appkit4/react-text-editor';

class Base64UploadAdapter {
  public loader: any;
  public reader: any;

  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.reader = new window.FileReader();
  }

  // Starts the upload process
  upload() {
    return new Promise((resolve, reject) => {
      const reader = this.reader;

      reader.addEventListener('load', () => {
        resolve({ default: reader.result });
      });

      reader.addEventListener('error', (err: any) => {
        reject(err);
      });

      reader.addEventListener('abort', () => {
        reject();
      });

      this.loader.file.then((file: any) => {
        reader.readAsDataURL(file);
      });
    });
  }

  abort() {
    this.reader.abort();
  }
}

const sampleConfig = {
  toolbar: [
    'fontFamily',
    'fontSize',
    'bold',
    'italic',
    'strikethrough',
    'underline',
    'bulletedList',
    'numberedList',
    'indent',
    'outdent',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    'alignment:justify',
    'link',
    'uploadImage',
    'undo',
    'redo',
  ],
};

export interface StyledTextEditorProps {
  onChange?: (data: string) => void;
  data?: string;
  className?: string;
}

// TODO: 새로고침 시 text editor, toolbar 기능 작동 안함...;;;^.ㅠ;;;;
export default function StyledTextEditor({
  onChange,
  data = '',
  className,
}: StyledTextEditorProps) {
  const [editorData, setEditorData] = React.useState(data);

  React.useEffect(() => {
    // data가 변경될 때마다 editorData 업데이트
    setEditorData(data);
  }, [data]);

  const onDocEditorReady = (editor: any) => {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
      return new Base64UploadAdapter(loader);
    };
  };

  const textEditorHandler = (event: any, editor: any, data: any) => {
    onChange && onChange(data);
  };

  console.log('data', data);

  return (
    <div className={`col-span-3 ${className}`}>
      <TextEditor
        data={editorData}
        config={sampleConfig}
        onReady={onDocEditorReady}
        onChange={textEditorHandler}
      />
    </div>
  );
}
