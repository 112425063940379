import { ButtonSet, ConfirmModal } from 'components/display';
import { DescriptionType, PageEndPoint } from 'types';
import {
  useDetail,
  useErrorActionsStore,
  useModalActions,
  useModalVariant,
  useModalVisible,
  usePostDetailActions,
  usePostDetailFetch,
} from 'store';
import { useLocation, useNavigate, useParams } from 'react-router';

import { INITIAL_POST_DETAIL } from 'const';
import { PanelHeader } from './component/PanelHeader';
import { PostEditor } from './component/PostEditor';
import React from 'react';
import { getErrorStatus } from 'utils';

//component import

interface PostEditorPropsType {
  pageName: 'Welcome' | '공지사항' | '자료실' | '문의게시판' | 'FAQ';
  descriptionList: DescriptionType;
  page: PageEndPoint;
}

export function PostEdit({ pageName, descriptionList, page }: PostEditorPropsType) {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { setError } = useErrorActionsStore();

  //store
  const detail = useDetail();
  const { resetDetail, setDetailItem } = usePostDetailActions();
  const { createPost, updatePost } = usePostDetailFetch();

  console.log(detail);

  //modal store
  const visible = useModalVisible();
  const variant = useModalVariant();
  const { onOpen, onClose } = useModalActions();

  const isEdit = location.pathname.includes('edit');
  const panelTitle = `${pageName} ${isEdit ? '수정' : '등록'}`;
  const panelDesc = descriptionList[`${isEdit ? 'edit' : 'create'}`];
  const isOptionFilled =
    detail.application !== '' &&
    detail.content !== INITIAL_POST_DETAIL.content &&
    detail.title !== '';

  React.useEffect(() => {
    onClose();
    !isEdit && resetDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  // modal의 확인 버튼 click event
  const onConfirm = async () => {
    //작성 취소 시 이전페이지로 이동
    if (variant === 'cancelEdit' || variant === 'cancelCreate') {
      resetDetail();
      navigate(`${location.pathname.replace(/\/(edit|create)/g, '')}`);
      return;
    }

    //작성된 게시글 업로드 시 fetch
    try {
      const res = isEdit ? await updatePost(page, id ? id : '0') : await createPost(page);
      if (res === 200) {
        onClose();
        navigate(`${location.pathname.replace(/\/(edit|create)/g, '')}`);
      }
    } catch (e) {
      const status = getErrorStatus(e);
      setError(true, status);
    }
  };

  return (
    <div>
      <section className='pannel-base bg-white p-7'>
        <PanelHeader
          title={panelTitle}
          description={panelDesc}
        />
        <PostEditor
          data={detail}
          onChange={setDetailItem}
        />
        <ButtonSet
          className='w-fit m-auto mt-3'
          buttons={[
            {
              icon: 'icon-delete-outline',
              kind: 'secondary',
              onClick: () => onOpen(isEdit ? 'cancelEdit' : 'cancelCreate'),
              role: 'button',
              txt: '취소',
            },
            {
              icon: 'icon-edit-outline',
              onClick: () => onOpen(isEdit ? 'edit' : 'create'),
              role: 'button',
              txt: isEdit ? '수정' : '등록',
              disabled: !isOptionFilled,
            },
          ]}
        />
      </section>
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </div>
  );
}
