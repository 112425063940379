import * as React from "react";

// component import
import { Button, TextArea } from "@appkit4/react-components";
import { UploadList } from "./UploadList";
import { commentListItem } from "types";

export interface CommentFormProps {
  type?: "modal" | "box";
  onClose?: () => void;
  replycontent: string;
  placeholderWithoutContent?: string;
  placeholderWithContent?: string;
  title?: string;
  hasTitle?: boolean;
  addBtnName?: string;
  className?: string;
  onAddClick?: () => void;
  onCommentContentChange: (
    key: keyof commentListItem,
    commentsContent: any
  ) => void;
  maxLength?: number | undefined;
  showAttachment?: boolean;
  disabled?: boolean;
  accept?: string;
  defaultAttachment?: string[];
}

export const CommentForm = React.forwardRef<HTMLDivElement, CommentFormProps>(
  (props, ref) => {
    const {
      type = "box",
      replycontent = "",
      className,
      title = "Add a new comment",
      placeholderWithContent = "Your comment",
      placeholderWithoutContent = "Write a comment...",
      addBtnName = "Add comment",
      onAddClick,
      onCommentContentChange,
      onClose,
      showAttachment = false,
      disabled = false,
      maxLength,
      accept = "*",
      hasTitle = true,
      defaultAttachment,
      ...otherProps
    } = props;

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [uploadedFiles, setUploadedFiles] = React.useState(
      defaultAttachment ? defaultAttachment : ([] as any)
    );

    const handleCommentChange = (value: string) => {
      onCommentContentChange("content", value);
    };

    const handleOnAddClick = () => {
      onAddClick?.();
      setUploadedFiles([]);
      onCommentContentChange("content", "");
      onCommentContentChange("file_path", []);
    };

    const handleChange = (event: any): void => {
      let files = event.target.files;
      const _uploadedFiles = [...uploadedFiles, ...files];
      onCommentContentChange("file_path", _uploadedFiles);
    };

    return (
      <div ref={ref} className={className} {...otherProps}>
        {hasTitle && (
          <div className="ap-feeds-comments-header">
            <div className="ap-feeds-comments-header-title">{title}</div>
            {type === "modal" && (
              <button
                type="button"
                aria-label="Close"
                className="ap-feeds-comments-footer-wrapper-attach"
                onClick={onClose}
              >
                <span className="Appkit4-icon icon-close-outline"></span>
              </button>
            )}
          </div>
        )}
        <div className="ap-feeds-comments-body">
          <TextArea
            maxLength={maxLength}
            title={
              replycontent.length > 0
                ? placeholderWithContent
                : placeholderWithoutContent
            }
            value={replycontent}
            onChange={handleCommentChange}
            disabled={disabled}
          ></TextArea>
        </div>
        <div className="ap-feeds-comments-footer-wrapper ">
          <div className="flex">
            <div
              className="ap-feeds-comments-footer-wrapper-attach"
              tabIndex={1}
              aria-label="attach link"
              role="button"
            >
              <span
                aria-hidden="true"
                className="Appkit4-icon icon-paperclip-outline"
              ></span>
              <input
                className="ap-feeds-comments-footer-attach-input"
                aria-label="attach link"
                ref={fileInputRef}
                type="file"
                multiple
                accept={accept}
                aria-hidden="true"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <div
              className="ap-feeds-comments-footer-wrapper-attach"
              tabIndex={2}
              role="button"
              onClick={() => setUploadedFiles([])}
            >
              <span
                aria-hidden="true"
                className="Appkit4-icon icon-refresh-outline"
              ></span>
            </div>
          </div>
          <div className="ap-feeds-comments-footer-buttons">
            <Button
              kind="primary"
              disabled={!replycontent && showAttachment}
              onClick={handleOnAddClick}
            >
              {addBtnName}
            </Button>
          </div>
        </div>
        {uploadedFiles.length > 0 && (
          <div className="ap-fileupload mt-3">
            <div className="ap-fileupload-wrapper ">
              <UploadList files={uploadedFiles} />
            </div>
          </div>
        )}
      </div>
    );
  }
);
