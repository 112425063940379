import { AUTH_URL } from 'const';
import { Button } from '@appkit4/react-components';

//component import
export default function AuthError() {
  return (
    <div className='w-full h-svh flex items-center justify-center bg-login bg-cover'>
      <section className='text-left text-white max-w-[30%]'>
        <div className='w-14 h-14 mb-5'>
          <img
            src='/images/home/whiteLogo.svg'
            alt='로고'
          />
        </div>
        <h2 className='text-2xl font-semibold mb-5'>Robotic App Portal </h2>
        <p>
          접근 권한이 확인되지 않습니다. <br />
          Retry 버튼을 눌러 인증을 다시 시도해주세요.
        </p>
        <Button
          onClick={() => (window.location.href = AUTH_URL)}
          className='w-full bg-white mt-10'
          kind='tertiary'
        >
          Retry
        </Button>
      </section>
    </div>
  );
}
