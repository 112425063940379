import { NOTICE, NOTICE_DESCRIPTION } from "const";

//component import
import { PostEdit } from "components/post";

export default function NotificationEditor() {
  return (
    <PostEdit
      pageName={NOTICE}
      descriptionList={NOTICE_DESCRIPTION}
      page="notice"
    />
  );
}
