import { INITAIL_ADMIN, INITIAL_PAGINATION } from 'const';
import { axiosClient, getErrorStatus } from 'utils';

import { AccountListStoreType } from 'types';
import { create } from 'zustand';

const useAccountList = create<AccountListStoreType>((set, get) => ({
  accountList: INITAIL_ADMIN,
  pagination: INITIAL_PAGINATION,
  selectedAccounts: [],
  actions: {
    setAccount: (newAccount) =>
      set(() => ({
        accountList: newAccount,
      })),
    setPagination: (newPagination) =>
      set(() => ({
        pagination: newPagination,
      })),
    setSelectedAccounts: (newSelectedAccounts) =>
      set(() => ({
        selectedAccounts: newSelectedAccounts,
      })),
  },
  fetch: {
    getAccountList: async (page, searchKey, permission, application) => {
      try {
        const apiClient = await axiosClient('text');
        const url = `accounts/?page=${page}&search-keyword=${searchKey}&permission-filter=${permission}&application-filter=${application}`;
        const res = await apiClient.get(url);
        const data = res.data;

        set(() => ({
          accountList: data['users'],
          pagination: data['pagination'],
        }));

        return res.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    createAccounts: async () => {
      try {
        const apiClient = await axiosClient('form');
        const accounts = get().accountList?.map((item) => ({
          username: item.username,
          email: item.email,
          company: item.company,
          permission: item.permission,
          application: item.application,
        }));

        const res = await apiClient.post(`accounts/create/`, {
          data: JSON.stringify(accounts),
        });
        const status = res.status;

        return status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    updateAccounts: async () => {
      try {
        const apiClient = await axiosClient('form');
        const accounts = get().accountList?.map((item) => ({
          username: item.username,
          email: item.email,
          company: item.company,
          permission: item.permission,
          application: item.application,
        }));

        const res = await apiClient.post(`accounts/edit/`, {
          data: JSON.stringify(accounts),
        });
        const status = res.status;

        return status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    deleteAccounts: async () => {
      try {
        const apiClient = await axiosClient('form');
        const selectedAccountIds = get().selectedAccounts;
        const matchedEmails = get()
          .accountList?.filter((account) => selectedAccountIds.includes(account.id))
          .map((account) => account.email);

        const res = await apiClient.post(`accounts/delete/`, {
          emails: JSON.stringify(matchedEmails),
        });
        const status = res.status;

        return status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
  },
}));

export const useAccounts = () => useAccountList((state) => state.accountList);
export const useAccountPagination = () => useAccountList((state) => state.pagination);
export const useSelectedAccounts = () => useAccountList((state) => state.selectedAccounts);
export const useAccountsActions = () => useAccountList((state) => state.actions);
export const useAccountsFetchs = () => useAccountList((state) => state.fetch);
