import { AccountItemType, PostDetailType } from "types";

export const INITIAL_PAGINATION = {
  has_previous: false,
  has_next: false,
  num_page: 0,
  current_page: 0,
};

export const INITIAL_POST_DETAIL = {
  id: 0,
  title: "",
  application: "",
  user: "",
  hits: 0,
  content: "<div>게시글을 입력해주세요.</div>",
  file_path: [],
  created_at: "",
  updated_at: "",
};

export const INITIAL_COMMENT = {
  id: 0,
  shortName: "",
  shortNameBgColor: "",
  fullName: "",
  commentsTime: "",
  content: "",
  replyList: [],
};

export const INITIAL_NOTICE_POST: PostDetailType = {
  id: 0,
  title: "",
  application: "",
  user: "",
  hits: 0,
  content: "<div>게시글을 입력해주세요.</div>",
  file_path: [],
  created_at: "",
  updated_at: "",
};

export const INITAIL_ADMIN: AccountItemType[] = [
  {
    id: 0,
    company: "",
    email: "",
    permission: "",
    application: [],
    username: "",
    is_staff: false,
  },
];
