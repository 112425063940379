import { QNA, QNA_DESCRIPTION } from "const";

//component import
import { PostList } from "components/post";

export default function QnA() {
  return (
    <PostList pageName={QNA} descriptionList={QNA_DESCRIPTION} page="qna" />
  );
}
