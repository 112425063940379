import {
  APP_INTRODUCTION,
  APP_INTRODUCTION_LIST,
  BANNER_TXT,
  CS_CENTER_BUTTONS,
  HOME_PREVIEW_BOARD_LIST,
  PATH_LIST,
  getBannerTit,
} from 'const';
import { List, ListItem } from '@appkit4/react-components';
import { formatDate, getRoutePath, isWithinLastWeek } from 'utils';
import { useErrorActionsStore, usePostListFetchs } from 'store';

import { PrevBoard } from 'types';
import React from 'react';
import { useNavigate } from 'react-router';

// component imports

export default function Home() {
  const [boardList, setboardList] = React.useState<PrevBoard[]>(HOME_PREVIEW_BOARD_LIST);

  // hook
  const navigate = useNavigate();
  const { getPostList } = usePostListFetchs();
  const { setError } = useErrorActionsStore();

  const userName = sessionStorage.getItem('userName');

  React.useEffect(() => {
    const loadData = async () => {
      const permissions = sessionStorage.getItem('permissions');
      const appStr = permissions ? JSON.parse(permissions).join(',') : '';
      const notices = await getPostList('notice', appStr, appStr, 1);
      const references = await getPostList('notice', appStr, appStr, 1);

      if (notices.status !== 200 || references.status !== 200) {
        setError(true, notices.status ? notices.status : 0);
        return;
      }

      setboardList((prev) => {
        const newBoardList = [...prev];

        newBoardList[0] = {
          ...newBoardList[0],
          data: notices.data.notices ? notices.data.notices : [],
        };

        newBoardList[1] = {
          ...newBoardList[1],
          data: references.data.references ? references.data.references : [],
        };

        return newBoardList;
      });
    };
    loadData();
  }, [getPostList, setError]);

  const renderItem = (item: any, title: keyof typeof PATH_LIST) => {
    const time = formatDate(item.created_at);
    const isNew = isWithinLastWeek(item.created_at);
    return (
      <ListItem
        key={item.id}
        role='option'
        onClick={() => {
          navigate(`${PATH_LIST[title]}/${item.id}`);
        }}
        className='!px-2 !py-2 !w-full max-w-full'
      >
        <p className='body-sm max-w-[60%] truncate'>{item.title}</p>
        <div
          className={`w-[35%] flex ${isNew ? 'justify-between' : 'justify-end'} items-center gap-2`}
        >
          {isNew && <p className='bedge-base body-xs px-2 py-[0.1rem] '>New</p>}
          <p className='body-sm truncate text-right'>{time}</p>
        </div>
      </ListItem>
    );
  };

  return (
    <>
      {/* Banner */}
      <article className='flex p-12 justify-between max-h-[400px] mb-16 pannel-base bg-white'>
        <div className='pr-5 max-w-[50%] flex flex-col justify-center gap-8'>
          <h2 className='heading-lg whitespace-pre-wrap'>
            {getBannerTit(userName ? userName : 'user')}
          </h2>
          <p className='body-md whitespace-pre-wrap'>{BANNER_TXT}</p>
        </div>
        <div className='h-full'>
          <img
            src='./images/home/MainBannerIllust.svg'
            alt='배너'
            className='h-full'
          />
        </div>
      </article>

      {/* App 소개 */}
      <article className='p-12 pannel-base bg-[#E8E8E8] mb-16'>
        <div className='text-center mb-12'>
          <h2 className='heading-lg whitespace-pre-wrap mb-3'>Save time, More value</h2>
          <p className='body-md whitespace-pre-wrap'>{APP_INTRODUCTION}</p>
        </div>
        <ul className='flex justify-between gap-10'>
          {APP_INTRODUCTION_LIST.map((item) => {
            const transition = 'transition-all duration-500';
            return (
              <li
                className={`pannel-base w-full relative h-[320px] group bg-white text-center ${transition} hover:!bg-primary-${item.id}00 cursor-pointer`}
                key={item.id}
                onClick={() =>
                  window.open(
                    'https://www.pwc.com/kr/ko/digital-solutions/robotic-application.html',
                    '_blank'
                  )
                }
              >
                <h4
                  className={`pt-10 pb-5 heading-sm !font-semibold ${transition} group-hover:pt-5 group-hover:pb-2 group-hover:body-sm ${
                    item.id === 1 ? '' : 'group-hover:text-white'
                  } group-hover:scale-75`}
                >
                  {item.name}
                </h4>
                <div
                  className={`flex justify-center bg-none p-5 m-auto rounded-xl ${transition} origin-top group-hover:scale-75  group-hover:bg-white`}
                >
                  <img
                    src={`./images/home/applicationIcon_${item.id}.svg`}
                    alt={`${item.name} icon`}
                    className='h-full w-auto'
                  />
                </div>
                <div
                  className={`absolute bottom-0 left-0 h-[145px] w-full px-6 rounded-b-lg opacity-0 ${transition} backdrop-blur group-hover:opacity-100 bg-primary-${
                    item.id
                  }00/50 ${item.id === 1 ? '' : 'text-white'} `}
                >
                  <p className='my-2 body-sm !font-semibold'>{item.subtit}</p>
                  <p className='mb-4 body-xs break-words text-wrap w-full'>{item.caption}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </article>

      <div className='flex justify-between'>
        {/* 고객센터 */}
        <article className='w-[32.5%] pannel-base bg-warnning-200 px-5 pb-5 pt-2 flex flex-col justify-between'>
          <div className='flex justify-between items-end mb-5'>
            <div className='mt-6'>
              <h4 className='heading-sm !font-semibold'>고객센터</h4>
              <div className='flex gap-1 mt-6'>
                <span
                  className='Appkit4-icon icon-time-fill ap-font-16'
                  aria-hidden='true'
                ></span>
                <p className='body-sm'>
                  평일 09:00 ~ 17:30
                  <br />
                  (토, 일, 공휴일 휴무)
                </p>
              </div>
            </div>
            <img
              src={`./images/home/CSIllust.svg`}
              alt={`cs center icon`}
              className='mb-2'
            />
          </div>
          {/* 고객센터 button */}
          <div className='flex justify-between gap-5'>
            {CS_CENTER_BUTTONS.map((item) => {
              const transition = 'transition-all duration-300';
              return (
                <button
                  className={`bg-white w-full p-3 rounded-md group `}
                  key={item.id}
                  onClick={() => {
                    const routePath = getRoutePath(item.name);
                    navigate(routePath);
                  }}
                >
                  <div
                    className={`bg-container-default rounded group-hover:bg-warnning-300 ${transition}`}
                  >
                    <span
                      className={`Appkit4-icon icon-${item.icon}-fill ap-font-24 py-6 text-container-selected-hover group-hover:text-white ${transition}`}
                      aria-hidden='true'
                    />
                  </div>
                  <span
                    className={`block mt-3 body-sm !font-semibold group-hover:text-warnning-300 ${transition}`}
                  >
                    {item.name}
                  </span>
                </button>
              );
            })}
          </div>
        </article>

        {/* 공지사항&자료실 */}
        <div className='pannel-base w-[64%] flex justify-between px-5 bg-white'>
          {boardList.map((item) => (
            <div
              className='w-[48.5%] pt-5 pb-3'
              key={item.id}
            >
              <h4 className='body-md !font-semibold pl-1'>{item.title}</h4>
              <div className='w-full h-[1px] bg-black my-3'></div>
              {item.data.length === 0 ? (
                <p className='w-full py-2 px-1 body-sm'>게시글이 없습니다.</p>
              ) : (
                <List
                  className='w-full !p-0'
                  itemKey='id'
                  data={item.data.slice(0, 5)}
                  renderItem={(data: any, index: number) => renderItem(data, item.title)}
                ></List>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
