import React from "react";
import { PostDetailType } from "types";
import { detailKeyKor, detailKeyMap } from "const";
import { formatDate } from "utils";

//component import
import { PanelHeader } from "./PanelHeader";
import { FileDownloadButton } from "components/display";

export interface PostContentProps {
  data: PostDetailType;
}

export const PostContent: React.FC<PostContentProps> = ({
  data,
}: PostContentProps) => {
  return (
    <>
      {/* 게시글 Header */}
      <PanelHeader
        title={data.title}
        postOption={
          <div className="flex w-full justify-between items-center">
            {detailKeyKor.map((korKey) => {
              const engKey = detailKeyMap[korKey];
              const value =
                korKey === "작성일"
                  ? formatDate(data[engKey].toString())
                  : data[engKey];
              return (
                <dl key={korKey} className="w-full flex gap-1">
                  <dt>{korKey} :</dt>
                  <dd>{value}</dd>
                </dl>
              );
            })}
          </div>
        }
      />
      {/* 게시글 내용 */}
      <div className="py-6 px-2 my-6 border-y-2 border-gray-200 border-solid w-full">
        <div
          dangerouslySetInnerHTML={{ __html: data.content }}
          className="ck"
        />
      </div>
      {/* 첨부파일 */}
      {data.file_path && data.file_path.length > 0 && (
        <div className="pb-6 pl-2 border-b-2 border-gray-200 border-solid ">
          <dl className="w-full flex gap-10">
            <dt>첨부파일</dt>
            <dd>
              <FileDownloadButton data={data.file_path} className="mb-1" />
            </dd>
          </dl>
        </div>
      )}
    </>
  );
};
