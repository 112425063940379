import axios, { ResponseType } from 'axios';

import { BASE_URL } from 'const';

export const axiosClient = (header: 'form' | 'text', responseType: ResponseType = 'json') => {
  const contentType = header === 'form' ? 'multipart/form-data' : 'text/html; charset=utf-8';

  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': contentType,
    },
    withCredentials: true,
    responseType,
  });

  // 리다이렉션 처리를 위한 인터셉터 추가
  client.interceptors.response.use(
    (response) => {
      if (response.status === 302) {
        const redirectUrl = response.headers['location'];
        if (redirectUrl) {
          window.location.href = `${redirectUrl}${window.location.search}`;
        }
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return client;
};
