import { PATH_LIST } from "const";

export const getRoutePath = (name: string) => {
  switch (name) {
    case "Welcome":
      return PATH_LIST["Welcome"];
    case "공지사항":
      return PATH_LIST["공지사항"];
    case "자료실":
      return PATH_LIST["자료실"];
    case "FAQ":
      return PATH_LIST["FAQ"];
    case "문의게시판":
      return PATH_LIST["문의게시판"];
    case "권한관리":
      return PATH_LIST["권한관리"];
    default:
      return PATH_LIST["Welcome"];
  }
};
