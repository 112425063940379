export default function Unauthorized() {
  return (
    <div className='w-full h-svh flex items-center justify-center bg-login bg-cover'>
      <section className='text-left text-white max-w-[30%]'>
        <div className='w-14 h-14 mb-5'>
          <img
            src='/images/home/whiteLogo.svg'
            alt='로고'
          />
        </div>
        <h2 className='text-2xl font-semibold mb-5'>Robotic App Portal </h2>
        <p className='mb-5'>
          PwC Robotic Application Service에 대한 권한이 없습니다. <br />
          아래 이메일로 문의 해주시기 바랍니다.
        </p>
        <a
          target='_blank'
          href='mailto:kr_robotic_application@pwc.com'
          className='font-semibold underline underline-offset-4 hover:text-white/70 transition-all'
          rel='noreferrer'
        >
          kr_robotic_application@pwc.com
        </a>
      </section>
    </div>
  );
}
