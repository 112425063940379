import { axiosClient, getDeletePostBody, getErrorStatus } from 'utils';

import { INITIAL_PAGINATION } from 'const/store';
import { PostListStoreType } from 'types';
import { create } from 'zustand';

const usePostList = create<PostListStoreType>((set, get) => ({
  list: null,
  pagination: INITIAL_PAGINATION,
  selectedPosts: [],
  actions: {
    setList: (newList) =>
      set(() => ({
        list: newList,
      })),
    setPagination: (newPagination) =>
      set(() => ({
        pagination: newPagination,
      })),
    setSelectedPosts: (newSelectedPosts) =>
      set(() => ({
        selectedPosts: newSelectedPosts,
      })),
  },
  fetch: {
    getPostList: async (endpoint, permissions, application, page) => {
      try {
        const apiClient = await axiosClient('text');
        const res = await apiClient.get(
          `${endpoint}/?page=${page}&permissions=${permissions}&application=${application}`
        );
        const data = res.data;

        set(() => ({
          list: data[`${endpoint}s`],
          pagination: data['pagination'],
        }));

        return { data: data, status: 200 };
      } catch (e) {
        const status = getErrorStatus(e);
        return { data: null, status };
      }
    },
    deleteMultiplePost: async (endpoint, permissions) => {
      const body = getDeletePostBody(endpoint, get().selectedPosts);
      const apiClient = await axiosClient('form');
      const url = `${endpoint}/delete-multiple/`;

      try {
        const deleteRes = await apiClient.post(url, body);

        if (deleteRes.status === 200) {
          await get().fetch.getPostList(endpoint, permissions, permissions, 1);
          get().actions.setSelectedPosts([]);
        }

        return deleteRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
  },
}));

export const useList = () => usePostList((state) => state.list);
export const usePagination = () => usePostList((state) => state.pagination);
export const useSelectedPosts = () => usePostList((state) => state.selectedPosts);
export const usePostListActions = () => usePostList((state) => state.actions);
export const usePostListFetchs = () => usePostList((state) => state.fetch);
