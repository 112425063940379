import { REFERENCE, REFERENCE_DESCRIPTION } from "const";

//component import
import { PostList } from "components/post";

export default function Reference() {
  return (
    <PostList
      pageName={REFERENCE}
      descriptionList={REFERENCE_DESCRIPTION}
      page="material"
    />
  );
}
