import {
  AccountItemKorType,
  AccountItemType,
  CommentDataItem,
  commentListItem,
  PostDetailType,
  PostListType,
  TableDataItemType,
} from "types";

// date fommatting : 24시간 이상일 경우 날짜 표기
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return "";

  const now = new Date();

  const diffMs = now.getTime() - date.getTime();
  const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  if (diffMs < 24 * 60 * 60 * 1000) {
    if (diffMins === 0 && diffHrs === 0) {
      return `now`;
    }
    if (diffHrs === 0) {
      return `${diffMins}min ago`;
    }
    return `${diffHrs}hour ago`;
  }

  const formattedDate = new Intl.DateTimeFormat("ko-KR", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);

  return formattedDate.replace(/\./g, ".").replace(/\s+/g, "");
};

export function isWithinLastWeek(timestamp: string): boolean {
  const currentDate = new Date();
  const inputDate = new Date(timestamp);

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(currentDate.getDate() - 7);

  return inputDate >= oneWeekAgo && inputDate <= currentDate;
}

// table data formatting (eng => kor)
export const fomattingTableData = (
  data: PostListType[]
): TableDataItemType[] => {
  return data.map((item) => ({
    id: item.id,
    번호: item.id,
    어플리케이션: item.application,
    제목: item.title,
    작성자: item.user,
    조회: item.hits,
    작성일시: formatDate(item.created_at),
    카테고리: item.category,
    상태: item.status,
  }));
};

export const formattingAccountsToKor = (data: AccountItemType[]) => {
  return data.map((item) => ({
    id: item.id,
    어플리케이션: item.application,
    이름: item.username,
    이메일: item.email,
    권한: item.permission,
    회사명: item.company,
    관리자: item.is_staff,
  }));
};

export const formattingAccountsToEng = (data: AccountItemKorType[]) => {
  return data.map((item) => ({
    id: item.id,
    company: item.회사명,
    application: item.어플리케이션,
    username: item.이름,
    email: item.이메일,
    permission: item.권한,
    is_staff: item.관리자,
  }));
};

export const getKorKey = (field: string) => {
  switch (field) {
    case "회사명":
      return "company";
    case "이름":
      return "username";
    case "이메일":
      return "email";
    case "권한":
      return "permission";
    case "어플리케이션":
      return "application";
    default:
      throw new Error(`Unknown field: ${field}`);
  }
};

// formatting Post Data(eng => kor)
export const formatPostData = (data: PostDetailType) => {
  const translationMap = {
    id: "번호",
    title: "제목",
    application: "어플리케이션",
    user: "작성자",
    hits: "조회수",
    content: "내용",
    file_path: "첨부파일",
    created_at: "작성일",
    updated_at: "수정일",
    category: "카테고리",
    status: "진행상태",
    comments: "댓글",
  };

  const result: { [key: string]: any } = {};

  if (data.created_at === data.updated_at) {
    result["작성일"] = formatDate(data.created_at);
  } else {
    result["작성일"] = formatDate(data.updated_at);
  }

  for (const key in data) {
    if (
      translationMap[key as keyof PostDetailType] &&
      key !== "created_at" &&
      key !== "updated_at"
    ) {
      const translatedKey = translationMap[key as keyof PostDetailType];
      let value = data[key as keyof PostDetailType];
      result[translatedKey] = value;
    } else {
      result[key] = data[key as keyof PostDetailType];
    }
  }

  return result;
};

export function getSimpleName(fileFullName: string): string {
  const flagIdx = fileFullName.lastIndexOf(".");

  return fileFullName.substring(0, flagIdx);
}

export function getFormatFileSize(size: number) {
  const fileSizeMB = (size / 1024 / 1024).toFixed(1);
  const fileSizeKB = (size / 1024).toFixed(1);
  let fileSize;
  if (parseFloat(fileSizeMB) < 1) {
    fileSize = fileSizeKB + "KB";
  } else {
    fileSize = fileSizeMB + "MB";
  }

  return fileSize;
}

export function getformattedCommentItem(
  item: CommentDataItem
): commentListItem {
  const images: string[] = item.file_path?.filter(
    (file) =>
      file.slice(-3).toLowerCase() === "jpg" ||
      file.slice(-3).toLowerCase() === "png"
  );

  const shortName = item.user[0] + item.user[1];

  const formattedData: commentListItem = {
    id: item.id,
    shortName: shortName.toUpperCase(),
    shortNameBgColor: "#415385",
    fullName: item.user,
    commentsTime: formatDate(item.created_at),
    content: item.content,
    replyList: [],
  };

  if (item.file_path) {
    formattedData.file_path = item.file_path;
  }

  if (images) {
    formattedData.images = images;
  }

  return formattedData;
}

export function getCommentList(data: CommentDataItem[]) {
  const parentComments: commentListItem[] = data
    .filter((item) => !item.parent_id)
    .map((item) => getformattedCommentItem(item));

  data.forEach((item) => {
    if (item.parent_id) {
      const parentComment = parentComments.find(
        (parent) => parent.id === item.parent_id
      );
      if (parentComment) {
        const replyItem = getformattedCommentItem(item);
        parentComment.replyList.push(replyItem);
        parentComment.commentsCount = parentComment.commentsCount
          ? parentComment.commentsCount + 1
          : 1;
      }
    }
  });

  return parentComments;
}

export function findCommentById(
  id: number,
  items: commentListItem[]
): commentListItem | undefined {
  for (const item of items) {
    if (item.id === id) {
      return item;
    }

    const foundInReplies = findCommentById(id, item.replyList);
    if (foundInReplies) {
      return foundInReplies;
    }
  }

  return undefined;
}
