import { FAQ, FAQ_DESCRIPTION } from "const";

// component import
import { PostList } from "components/post";

export default function FAQPage() {
  return (
    <PostList pageName={FAQ} descriptionList={FAQ_DESCRIPTION} page="faq" />
  );
}
