import { ButtonSet, ConfirmModal } from 'components/display';
import { DSD_WIZARD, PATH_LIST } from 'const';
import { useErrorActionsStore, useModalActions, useModalVariant, useModalVisible } from 'store';

import { MANUAL_DEFAULT } from './Manual';
import { PanelHeader } from 'components/post';
import React from 'react';
import StyledTextEditor from 'components/display/StyledTextEditor';
import { getErrorStatus } from 'utils';
import { useNavigate } from 'react-router';

export default function ManualEdit() {
  const [content, setContent] = React.useState(MANUAL_DEFAULT);
  const navigate = useNavigate();

  //store
  const visible = useModalVisible();
  const variant = useModalVariant();
  const { onOpen, onClose } = useModalActions();
  const { setError } = useErrorActionsStore();

  // modal의 확인 버튼 click event
  const onConfirm = async () => {
    //작성 취소 시 이전페이지로 이동
    if (variant === 'cancelEdit') {
      onClose();
      navigate(`${PATH_LIST[DSD_WIZARD]}`);
      return;
    }
    //작성된 게시글 업로드 시 fetch
    try {
      // TODO: api 연결
      onClose();
      navigate(`${PATH_LIST[DSD_WIZARD]}`);
    } catch (e) {
      const status = getErrorStatus(e);
      setError(true, status);
    }
  };

  return (
    <>
      <section className='pannel-base bg-white p-7'>
        <PanelHeader title='설명서 수정' />
        <StyledTextEditor
          data={content}
          onChange={(data) => setContent(data)}
          className='mt-6'
        />
        <ButtonSet
          className='w-fit m-auto mt-7'
          buttons={[
            {
              icon: 'icon-delete-outline',
              kind: 'secondary',
              onClick: () => onOpen('cancelEdit'),
              role: 'button',
              txt: '취소',
            },
            {
              icon: 'icon-edit-outline',
              onClick: () => onOpen('edit'),
              role: 'button',
              txt: '수정',
            },
          ]}
        />
      </section>
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
}
