import { REFERENCE, REFERENCE_DESCRIPTION } from "const";

//component import
import { PostEdit } from "components/post";

export default function ReferenceEditor() {
  return (
    <PostEdit
      pageName={REFERENCE}
      descriptionList={REFERENCE_DESCRIPTION}
      page="material"
    />
  );
}
