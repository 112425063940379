import { AuthStoreType } from 'types';
import { create } from 'zustand';

const useAuthStore = create<AuthStoreType>((set) => ({
  permissions: [],
  filter: [{ label: '', value: '' }],
  actions: {
    setPermissions: (newPermissions) =>
      set(() => ({
        permissions: newPermissions,
        filter: newPermissions.map((item) => {
          return { label: item, value: item, disabled: true };
        }),
      })),
  },
}));

export const usePermissions = () => useAuthStore((state) => state.permissions);
export const useFilterList = () => useAuthStore((state) => state.filter);
export const useAuthActions = () => useAuthStore((state) => state.actions);
