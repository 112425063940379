import { ADMIN_NAV_ITEM, NAV_LIST, PATH_LIST } from 'const';
import { Navigation, NavigationItem } from '@appkit4/react-components';
import { checkAdmin, getApplicaions, getRoutePath } from 'utils';
import { useLocation, useNavigate } from 'react-router';

import React from 'react';

// component imports

export const SideNavBar = () => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState('0');

  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = checkAdmin();
  const applicaions = getApplicaions();

  const navBarList: NavigationItem[] = React.useMemo(() => {
    return isAdmin ? [...NAV_LIST, ADMIN_NAV_ITEM] : NAV_LIST;
  }, [isAdmin]);

  React.useEffect(() => {
    type PathListType = typeof PATH_LIST;
    type PathKey = keyof PathListType;

    const key = (Object.keys(PATH_LIST) as Array<PathKey>).find(
      (key) => PATH_LIST[key] === location.pathname
    );

    const nowSelectedKey = navBarList.findIndex((item) => item.name === key);
    setSelectedKey(nowSelectedKey.toLocaleString());
  }, [location.pathname, navBarList]);

  return (
    <Navigation
      width={330}
      navList={navBarList}
      showTooltip={true}
      collapsed={isCollapsed}
      selectedKey={selectedKey}
      hasHeader={false}
      defaultOpenKeys={[]}
      onItemClick={(event: any, item: NavigationItem, key: string) => {
        setSelectedKey(key);
        const routePath = getRoutePath(item.name);

        switch (item.name) {
          case '삼일회계법인 공식 홈페이지':
            window.open('https://www.pwc.com/kr/ko', '_blank');
            break;
          case '권한관리':
            navigate(`${routePath}`);
            break;
          default:
            navigate(`${routePath}?applicaion=${applicaions.join(',')}`);
        }
      }}
      onClickCollapseEvent={(collapsedValue: boolean) => {
        setIsCollapsed(collapsedValue);
      }}
      hasFooter={true}
      className='!sticky top-0 left-0 pt-14'
    ></Navigation>
  );
};
