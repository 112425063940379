//page name
export const HOME = 'Welcome';
export const NOTICE = '공지사항';
export const REFERENCE = '자료실';
export const FAQ = 'FAQ';
export const QNA = '문의게시판';
export const ADMIN = '권한관리';
export const DSD_WIZARD = 'DSD Wizard 설명서';
export const AUTH = '인증확인';
export const AUTHERROR = '인증실패';
export const UNAUTHORIZED = '권한없읍';

export const PATH_LIST = {
  Welcome: '/home',
  공지사항: '/notification',
  자료실: '/reference',
  문의게시판: '/qna',
  FAQ: '/faq',
  권한관리: '/admin',
  'DSD Wizard 설명서': '/manual',
  인증확인: '/',
  인증실패: '/auth-error',
  권한없읍: '/unauthorized',
} as const;

export const BASE_URL = 'https://aezikybgadwa002.azurewebsites.net';

// Oauth path
const OAUTH2_CLIENT_ID = 'urn:roboticapp:dev';
const OAUTH2_REDIRECT_URI = 'https://robotic-dev.pwc.kr';
const OAUTH2_AUTH_ENDPOINT = 'https://login-stg.pwc.com/openam/oauth2/authorize';
const OAUTH2_SCOPES = 'openid profile email uid';

export const AUTH_URL = `${OAUTH2_AUTH_ENDPOINT}?response_type=code&client_id=${OAUTH2_CLIENT_ID}&redirect_uri=${OAUTH2_REDIRECT_URI}&scope=${OAUTH2_SCOPES}`;

//mapping key
export const KEY_VALUES = {
  // Navigation Keys
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  END: 'End',
  HOME: 'Home',
  PAGE_DOWN: 'PageDown',
  PAGE_UP: 'PageUp',

  // Whitespace Keys
  ENTER: 'Enter',
  TAB: 'Tab',
  SPACE: ' ',

  // Editing Keys
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
  COMMA: ',',

  // UI Keys
  ESC: 'Escape',
  SHIFT: 'Shift',
};

export const detailKeyMap: {
  [key: string]: 'user' | 'created_at' | 'application';
} = {
  작성자: 'user',
  작성일: 'created_at',
  카테고리: 'application',
};

export const detailKeyKor: string[] = Object.keys(detailKeyMap);
