import { ADMIN, INITAIL_ADMIN, PATH_LIST, TABLE_HEADER_LIST_ADMIN } from 'const';
import {
  Badge,
  Checkbox,
  Loading,
  Pagination,
  Search,
  Select,
  Tooltip,
  ValueType,
} from '@appkit4/react-components';
import { ConfirmModal, TableBase } from 'components/display';
import {
  formattingAccountsToKor,
  getApplicaions,
  getErrorStatus,
  getFilterList,
  getPermissions,
  useDebounce,
  useToastPopup,
} from 'utils';
import {
  useAccountPagination,
  useAccounts,
  useAccountsActions,
  useAccountsFetchs,
  useErrorActionsStore,
  useModalActions,
  useModalVariant,
  useModalVisible,
  useSelectedAccounts,
} from 'store';
import { useNavigate, useSearchParams } from 'react-router-dom';

import React from 'react';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';

// component import

export default function Admin() {
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const { onOpenPopup } = useToastPopup();

  //store
  const originData = useAccounts();
  const selectedAccounts = useSelectedAccounts();
  const pagination = useAccountPagination();
  const { setSelectedAccounts, setAccount, setPagination } = useAccountsActions();
  const { getAccountList, deleteAccounts } = useAccountsFetchs();

  //modal store
  const visible = useModalVisible();
  const variant = useModalVariant();
  const { onOpen, onClose } = useModalActions();
  const { setError } = useErrorActionsStore();

  //const
  const permission = getPermissions();
  const application = getApplicaions();
  const applicationList = getFilterList(application);
  const filterLsit = [
    {
      label: 'Accounts',
      type: 'group',
      children: permission.map((item) => ({ label: item, value: item })),
    },
    {
      label: 'Applicaions',
      type: 'group',
      children: applicationList,
    },
  ];

  //debounding when change search key
  const debouncedSearchHandler = useDebounce(async (debouncedValue) => {
    try {
      const page = pagination.current_page === 0 ? 1 : pagination.current_page;
      const searchKey = debouncedValue;
      const permission = searchParams.get('permission') ? searchParams.get('permission') : '';
      const application = searchParams.get('application') ? searchParams.get('application') : '';
      await getAccountList(page, searchKey, permission, application);
      setSearchKeyword(debouncedValue);
    } catch (e) {
      onOpenPopup({
        message: '검색 실패. 잠시 후 다시 시도해주세요.',
        status: 'error',
      });
      setSearchKeyword('');
    }
  }, 500);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        const page = pagination.current_page === 0 ? 1 : pagination.current_page;
        const searchKey = searchKeyword;
        const permission = searchParams.get('permission') ? searchParams.get('permission') : '';
        const application = searchParams.get('application') ? searchParams.get('application') : '';
        await getAccountList(page, searchKey, permission, application);
      } catch (e) {
        const status = getErrorStatus(e);
        setError(true, status);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccountList, searchKeyword, searchParams, pagination.current_page]);

  if (!originData) {
    return (
      <Loading
        loadingType='linear'
        className='fixed top-[50%] left-[50%]'
      />
    );
  }

  const onSelectFilter = (value: SelectValue) => {
    // 검색 파라미터 초기화
    searchParams.delete('application');
    searchParams.delete('permission');

    let application: ValueType[] = [];
    let permission: ValueType[] = [];

    // value가 배열인 경우
    if (Array.isArray(value)) {
      value.forEach((item) => {
        if (item.toString().includes('User') || item.toString().includes('Manager')) {
          permission.push(item);
        } else {
          application.push(item);
        }
      });

      if (application.length > 0) {
        searchParams.set('application', application.join(','));
      }
      if (permission.length > 0) {
        searchParams.set('permission', permission.join(','));
      }

      setSearchParams(new URLSearchParams(searchParams.toString()));
    }
  };

  const onDeleteConfirm = async () => {
    const status = await deleteAccounts();

    if (status === 200) {
      const page = pagination.current_page === 0 ? 1 : pagination.current_page;
      const searchKey = searchKeyword;
      const permission = searchParams.get('permission') ? searchParams.get('permission') : '';
      const application = searchParams.get('application') ? searchParams.get('application') : '';
      await getAccountList(page, searchKey, permission, application);
    }

    onClose();
    onOpenPopup({
      message: status === 200 ? '계정 삭제 성공' : '계정 삭제 실패',
      status: status === 200 ? 'success' : 'error',
    });
  };

  //render Read only cell
  const renderReadOnlyCell = (row: any, field: string) => {
    const onCheckboxChange = (value: boolean, event: React.SyntheticEvent) => {
      //prevent event bubbling
      event.stopPropagation();

      //change checkbox status
      if (value) {
        selectedAccounts.push(row['id']);
      } else {
        selectedAccounts.splice(selectedAccounts.indexOf(row['id']), 1);
      }

      setSelectedAccounts([...selectedAccounts]);
    };

    if (!(field in row)) return '';

    switch (field) {
      case '권한':
        const bgColor = row[field] === 'User' ? '!bg-[#4DACF1]' : '!bg-[#86DB4F]';
        return (
          <Badge
            value={row[field]}
            type={'primary'}
            className={bgColor}
          />
        );
      case '회사명':
        return originData.length === 0 ? (
          <span className='ml-1'>{row[field]}</span>
        ) : (
          <Checkbox
            value={row[field]}
            checked={selectedAccounts.includes(row['id'])}
            onChange={(value, event) => onCheckboxChange(value, event)}
          >
            <span className='ml-1'>{row[field]}</span>
          </Checkbox>
        );
      case '어플리케이션':
        return row[field].length === 3 ? (
          <Badge
            value={'All Application'}
            type={'info'}
          />
        ) : (
          row[field].map((item: string) => {
            return (
              <Badge
                value={item}
                type={'primary'}
              />
            );
          })
        );
      default:
        return <span>{row[field]}</span>;
    }
  };

  const tableData =
    originData.length === 0
      ? [{ id: 0, 회사명: '등록된 계정이 없습니다.' }]
      : formattingAccountsToKor(originData);

  const ControlButtons = [
    {
      tootip: '계정 등록',
      icon: 'plus',
      onClick: () => {
        setAccount(INITAIL_ADMIN);
        navigator(`${PATH_LIST.권한관리}/edit?type=create`);
      },
      disabled: false,
    },
    {
      tootip: '선택 수정',
      icon: 'edit',
      onClick: () => {
        const newData = originData.filter((account) => selectedAccounts.includes(account.id));
        setAccount(newData);
        navigator(`${PATH_LIST.권한관리}/edit?type=update`);
      },
      disabled: selectedAccounts.length === 0,
    },
    {
      tootip: '선택 삭제',
      icon: 'delete',
      onClick: () => onOpen('deleteAccount'),
      disabled: selectedAccounts.length === 0,
    },
  ];

  return (
    <section className='pannel-base bg-white p-7'>
      {/* header */}
      <div className='flex justify-between items-center'>
        <h3 className='heading-md !font-semibold ml-1 w-1/4'>{ADMIN}</h3>
        <div className='flex items-center justify-center w-2/4'>
          <Search
            searchType={'secondary'}
            onChange={debouncedSearchHandler}
            placeholder='회사명, 이름, 이메일을 입력하여 검색하세요.'
            className='w-3/5'
            showHistory
            onClear={() => debouncedSearchHandler('')}
          />
          <div className='relative w-[30%] h-[48px] mr-2'>
            <div className='absolute top-0 left-0 z-30 w-full'>
              <Select
                placeholder='Filter'
                multiple
                data={filterLsit}
                valueKey={'value'}
                labelKey={'label'}
                onSelect={onSelectFilter}
                defaultValue={`${permission}`.split(',')}
              ></Select>
            </div>
          </div>
        </div>
        <div className='w-1/4 flex items-center justify-end'>
          {ControlButtons.map((item, idx) => (
            <Tooltip
              trigger='hover'
              position='bottom'
              content={item.tootip}
              key={idx}
            >
              <button
                className='p-3 border-base rounded mr-1 hover:bg-container-selected-hover transition-all disabled:cursor-not-allowed disabled:border-primary-100 disabled:text-primary-100 disabled:bg-white'
                onClick={item.onClick}
                disabled={item.disabled}
              >
                <span className={`Appkit4-icon icon-${item.icon}-outline`} />
              </button>
            </Tooltip>
          ))}
        </div>
      </div>
      {/* table */}
      <TableBase
        headerData={TABLE_HEADER_LIST_ADMIN}
        originalData={tableData}
        setSelected={setSelectedAccounts}
        selected={selectedAccounts}
        renderCheckedCell={renderReadOnlyCell}
      />
      <div className='flex justify-center'>
        <Pagination
          current={pagination.current_page}
          defaultCurrent={1}
          total={pagination.num_page}
          onPageChange={(page) => setPagination({ ...pagination, current_page: page })}
        />
      </div>
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={onDeleteConfirm}
        onClose={onClose}
      />
    </section>
  );
}
