import { ButtonSet, ConfirmModal } from 'components/display';
import {
  useDetail,
  useErrorActionsStore,
  useModalActions,
  useModalVariant,
  useModalVisible,
  usePostDetailFetch,
} from 'store';
import { useLocation, useNavigate, useParams } from 'react-router';

import { INITIAL_POST_DETAIL } from 'const';
import { Loading } from '@appkit4/react-components';
import { PageEndPoint } from 'types';
import { PostContent } from './component/PostContent';
import React from 'react';
import { checkAdmin } from 'utils';

//component import

interface PostDetailPropsType {
  page: PageEndPoint;
}

export function PostDetail({ page }: PostDetailPropsType) {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const isAdmin = checkAdmin();

  //store
  const detail = useDetail();
  const { getPost, deleteSinglePost } = usePostDetailFetch();
  const { setError } = useErrorActionsStore();

  //modal store
  const visible = useModalVisible();
  const variant = useModalVariant();
  const { onOpen, onClose } = useModalActions();

  React.useEffect(() => {
    onClose();

    if (!id) return; // TODO : error 처리 필요

    // 최초 랜더 시 게시글 data fetch
    const loadData = async () => {
      const resStatus = await getPost(page, id);
      resStatus !== 200 && setError(true, resStatus);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (detail === INITIAL_POST_DETAIL) {
    return (
      <Loading
        loadingType='linear'
        className='fixed top-[50%] left-[50%]'
      />
    );
  }

  // 게시글 단일 삭제 확인 버튼 click event
  const onDeleteConfirm = async () => {
    if (!id) return;
    const resStatus = await deleteSinglePost(page, id);

    if (resStatus === 200) {
      onClose();
      const basePath = location.pathname.replace(/\/\d+$/, '');
      navigate(basePath);
    } else {
      setError(true, resStatus);
    }
  };

  // 게시글 수정 버튼 click event
  const onEdit = () => {
    navigate(`${location.pathname}/edit`);
  };

  return (
    <div>
      <section className='pannel-base bg-white p-7'>
        <PostContent data={detail} />
        {/* 관리자 편집 버튼 */}
        {isAdmin && (
          <ButtonSet
            className='w-fit m-auto mt-6'
            buttons={[
              {
                icon: 'icon-delete-outline',
                kind: 'secondary',
                onClick: () => onOpen('delete'),
                role: 'button',
                txt: '삭제',
              },
              {
                icon: 'icon-edit-outline',
                onClick: onEdit,
                role: 'button',
                txt: '수정',
              },
            ]}
          />
        )}
      </section>
      <ConfirmModal
        visible={visible}
        variant={variant}
        onCancel={onClose}
        onConfirm={onDeleteConfirm}
        onClose={onClose}
      />
    </div>
  );
}
