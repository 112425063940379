import { FAQ, FAQ_DESCRIPTION } from "const";

//component import
import { PostEdit } from "components/post";

export default function FAQEditor() {
  return (
    <PostEdit pageName={FAQ} descriptionList={FAQ_DESCRIPTION} page="faq" />
  );
}
