import { ADMIN, FILTER_LIST, INITAIL_ADMIN, PERMISSION_LIST, TABLE_HEADER_LIST_ADMIN } from 'const';
import { Button, Combobox, Input, Loading, Select, Tooltip } from '@appkit4/react-components';
import React, { ChangeEvent } from 'react';
import { formattingAccountsToEng, formattingAccountsToKor, getKorKey, useToastPopup } from 'utils';
import { useAccounts, useAccountsActions, useAccountsFetchs, useSelectedAccounts } from 'store';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountItemType } from 'types';
import { SelectValue } from '@appkit4/react-components/esm/combobox/Combobox';
import { TableBase } from 'components/display';
import { read } from 'xlsx';

// component import

export default function AdminEdit() {
  const [updateKey, setUpdateKey] = React.useState<number>(0);
  //hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { onOpenPopup, toastRef } = useToastPopup();

  //constant
  const isCreateMode = location.search.includes('create');
  const permissions = PERMISSION_LIST.map((item) => ({
    label: item,
    value: item,
  }));
  const applications = [{ label: 'Applications', type: 'group', children: FILTER_LIST }];

  // store
  const originData = useAccounts();
  const selectedAccounts = useSelectedAccounts();
  const { setSelectedAccounts, setAccount } = useAccountsActions();
  const { createAccounts, updateAccounts } = useAccountsFetchs();

  if (!originData) {
    return (
      <Loading
        loadingType='linear'
        className='fixed top-[50%] left-[50%]'
      />
    );
  }

  // fomatting response data(Eng) to Table Data(Kor)
  const tableData = formattingAccountsToKor(originData);

  // Update originData based on the input change
  const onChangeValue = (value: any, field: string, id: number) => {
    const fieldKey = getKorKey(field);

    // Update the specific item in the originData array
    const newData = originData.map((item) =>
      item.id === id ? { ...item, [fieldKey]: value } : item
    );

    setAccount(newData);
  };

  // delete selected row
  const deleteRow = (id: number) => {
    const newData = originData.filter((item) => item.id !== id);
    setAccount(newData);
    setUpdateKey((prev) => prev + 1);
  };

  // duplicate selected row
  const duplicateRow = (row: any) => {
    const newRow = formattingAccountsToEng([row])[0];
    const newData = [...originData, newRow];
    const sortedData = newData.map((item, idx) => ({ ...item, id: idx }));

    setAccount(sortedData);
  };

  // call api create or update when click save button
  const onSave = async () => {
    const status = isCreateMode ? await createAccounts() : await updateAccounts();

    if (status === 200) {
      navigate('/admin');
      onOpenPopup({
        message: `계정 ${isCreateMode ? '등록' : '수정'} 성공`,
        status: 'success',
      });
    } else {
      onOpenPopup({
        message: `계정 ${isCreateMode ? '등록' : '수정'} 실패`,
        status: 'error',
      });
    }
  };

  const renderInputCell = (row: any, field: string) => {
    if (!(field in row)) return '';

    switch (field) {
      case '권한':
        return (
          <Select
            key={`${field}-${row.id}-${updateKey}`}
            data={permissions}
            hideTitleOnInput={true}
            defaultValue={row[field]}
            placeholder={field}
            onSelect={(value: SelectValue) => onChangeValue(value, field, row.id)}
          />
        );
      case '어플리케이션':
        return (
          <div
            className='flex gap-1'
            key={`app-${updateKey}`}
          >
            <Combobox
              key={`${field}-${row.id}-${updateKey}`}
              data={applications}
              showSelectAll={false}
              valueKey={'value'}
              labelKey={'label'}
              defaultValue={row[field]}
              placeholder={field}
              multiple
              className='max-w-[250px]'
              onSelect={(value: SelectValue) => onChangeValue(value, field, row.id)}
            />
            {isCreateMode && (
              <>
                <Tooltip
                  trigger='hover'
                  position='bottom'
                  content='복제'
                >
                  <button
                    className='p-3 border-base rounded bg-white hover:bg-container-selected-hover transition-all'
                    onClick={() => duplicateRow(row)}
                  >
                    <span className='Appkit4-icon icon-duplicate-outline' />
                  </button>
                </Tooltip>
                <Tooltip
                  trigger='hover'
                  position='bottom'
                  content='삭제'
                >
                  <button
                    className='p-3 border-base rounded bg-white text-[#C52A1A] hover:bg-container-selected-hover transition-all disabled:cursor-not-allowed disabled:border-primary-100 disabled:text-primary-100 disabled:bg-white'
                    onClick={() => deleteRow(row.id)}
                    disabled={originData.length === 1}
                  >
                    <span className='Appkit4-icon icon-delete-outline ' />
                  </button>
                </Tooltip>
              </>
            )}
          </div>
        );
      default:
        return (
          <Input
            key={`${field}-${row.id}-${updateKey}`}
            type={'text'}
            allowClear={true}
            defaultValue={row[field]}
            hideTitleOnInput={true}
            placeholder={field}
            onChange={(value: string) => {
              onChangeValue(value, field, row.id);
            }}
          />
        );
    }
  };

  const bulkFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (!files) {
      onOpenPopup({
        message: '파일 업로드 실패. 잠시 후 다시 시도해주세요.',
        status: 'error',
      });
      return;
    }

    const file = files[0];
    const fileData = await file.arrayBuffer();
    const readOutput = read(fileData);
    const sheetData = readOutput.Sheets[readOutput.SheetNames[0]];
    const sheetArray = Object.entries(sheetData);
    const lookup: { [key: string]: string } = {};
    const dataArr: AccountItemType[] = [];

    sheetArray.forEach((item) => {
      const [key, value] = item;
      if (
        key.startsWith('A') ||
        key.startsWith('B') ||
        key.startsWith('C') ||
        key.startsWith('D') ||
        key.startsWith('E') ||
        key.startsWith('F')
      ) {
        lookup[key] = value.v;
      }
    });

    for (let i = 3; i < sheetArray.length - 2; i += 1) {
      const aKey = `A${i}`;
      const bKey = `B${i}`;
      const cKey = `C${i}`;
      const dKey = `D${i}`;
      const eKey = `E${i}`;
      const fKey = `F${i}`;
      const aplicationArr = [];
      dKey.includes('O') && aplicationArr.push('Robotic PA');
      eKey.includes('O') && aplicationArr.push('Robotic XBRL');
      fKey.includes('O') && aplicationArr.push('사업보고서 작성 플랫폼');

      if (lookup[aKey]) {
        dataArr.push({
          id: i,
          company: lookup[aKey],
          username: lookup[bKey],
          email: lookup[cKey],
          permission: 'User',
          is_staff: false,
          application: aplicationArr,
        });
      }
    }

    setUpdateKey((prev) => prev + 1);
    setAccount(dataArr);
  };

  return (
    <section className='pannel-base bg-white p-7'>
      {/* header */}
      <div className='flex justify-between items-center mb-2'>
        <h3 className='heading-md !font-semibold ml-1 w-1/4'>{ADMIN}</h3>
        <div className='w-1/4 flex items-center justify-end'>
          <Tooltip
            trigger='hover'
            position='bottom'
            content='파일업로드'
          >
            <label
              className='p-3 border-base rounded mr-1 hover:bg-container-selected-hover transition-all'
              htmlFor='fileupload'
            >
              <span className='Appkit4-icon icon-upload-outline' />
              <input
                type='file'
                accept='.xlsx, .xls'
                className='hidden'
                id='fileupload'
                onChange={bulkFileUpload}
              />
            </label>
          </Tooltip>
          <Tooltip
            trigger='hover'
            position='bottom'
            content='이전페이지'
          >
            <button
              className='p-3 border-base rounded mr-1 hover:bg-container-selected-hover transition-all'
              onClick={() => navigate(-1)}
            >
              <span className='Appkit4-icon icon-return-outline' />
            </button>
          </Tooltip>
          <Button
            ref={toastRef}
            kind='primary'
            icon='icon-confirmed-outline'
            className='h-full !py-3'
            onClick={onSave}
            disabled={originData === INITAIL_ADMIN}
          >
            저장
          </Button>
        </div>
      </div>
      {/* table */}
      <TableBase
        headerData={TABLE_HEADER_LIST_ADMIN}
        originalData={tableData}
        setSelected={setSelectedAccounts}
        selected={selectedAccounts}
        renderCheckedCell={renderInputCell}
        useCheck={false}
      />
    </section>
  );
}
