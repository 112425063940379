import * as React from "react";
import ClassNames from "classnames";
import { getFormatFileSize } from "utils";

interface dataProps {
  showFullFileName?: boolean;
  fileListItemClassName?: string;
  files: any;
  multiple?: any;
  autoUpload?: boolean;
  config?: Object;
}

export const UploadList: React.FC<dataProps> = (props: dataProps) => {
  const { config = {} }: any = props;

  //file list item render
  const renderSuccessContext = (
    filename: string,
    filesize: number,
    i: number
  ) => {
    const loadClass = ClassNames("ap-fileupload-loaded", {
      "modal-loaded": config.type === "modal",
    });
    const fileNameClass = ClassNames("ap-fileupload-fileName", {
      "color-dark": config.type === "modal",
    });
    const fileSizeClass = ClassNames("ap-fileupload-fileSize", {
      "color-dark": config.type === "modal",
    });
    return (
      <div className="ap-fileupload-fileList" key={i}>
        <div className={loadClass}>
          <span aria-hidden="true">
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <title>Icon</title>
              <g
                id="Icons"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g transform="translate(-632.000000, -612.000000)" id="Icon">
                  <g transform="translate(632.000000, 612.000000)">
                    <rect
                      id="Rectangle"
                      transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    ></rect>
                    <path
                      className="doc-text"
                      d="M6,20 L14.3955244,20 L18.4480592,15.4927652 L18.4480592,4 L6,4 L6,20 Z M17.0528392,15.5913124 L14.5286494,18.672207 L14.5286494,15.5913124 L17.0528392,15.5913124 Z M17.5127258,4.93533334 L17.5127258,14.9014824 L13.5933161,14.9014824 L13.5933161,19.0646667 L6.93533334,19.0646667 L6.93533334,4.93533334 L17.5127258,4.93533334 Z"
                      id="Shape"
                      fill="#252525"
                      fillRule="nonzero"
                      transform="translate(12.224030, 12.000000) scale(-1, 1) rotate(-180.000000) translate(-12.224030, -12.000000) "
                    ></path>
                    <rect
                      className="doc-text"
                      id="Rectangle"
                      fill="#252525"
                      x="9"
                      y="15"
                      width="7"
                      height="1"
                    ></rect>
                    <rect
                      className="doc-text"
                      id="Rectangle"
                      fill="#252525"
                      x="9"
                      y="12"
                      width="7"
                      height="1"
                    ></rect>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span className="ap-fileupload-fileSpan">
            <span className={fileNameClass}>
              <span className="fileName" title={filename}>
                {filename}
              </span>
            </span>
            <span className={fileSizeClass}>
              {config.size && `(${getFormatFileSize(filesize)})`}
            </span>
          </span>
          <span className="ap-fileupload-success">
            <span className="Appkit4-icon icon-circle-checkmark-outline"></span>
          </span>
        </div>
      </div>
    );
  };

  const { files } = props;
  const classes = ClassNames("ap-fileupload-fileLists", {
    multiple: props.multiple,
  });

  //file list render
  const list = files.map((file: File | string, i: number) => {
    return typeof file === "string"
      ? renderSuccessContext(file, 0, i)
      : renderSuccessContext(file.name, file.size, i);
  });
  return <div className={classes}>{list}</div>;
};
