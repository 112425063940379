import { COMMENT_MENU_LIST } from 'const';
import { DropdownMenuItem } from '@appkit4/react-components';
import React from 'react';
import { useOutsideClick } from 'utils';

// component import

interface CommentItemMenuProps {
  onSelect?: (value: string) => void;
}
export function CommentItemMenu({ onSelect }: CommentItemMenuProps) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [menuHeight, setMenuHeight] = React.useState(0);

  const menuRef = useOutsideClick<HTMLDivElement>(() => setIsMenuOpen(false));

  const onMenuClick = (value: string) => {
    onSelect && onSelect(value);
    setIsMenuOpen((prev) => !prev);
  };

  React.useEffect(() => {
    // menuRef가 열릴 때 height 계산
    if (isMenuOpen && menuRef.current) {
      setMenuHeight(menuRef.current.offsetHeight);
    }
  }, [isMenuOpen, menuRef]);

  return (
    <div className='relative text-right'>
      <div
        className='p-2 rounded hover:bg-container-selected-hover/50 !inline-block focus:!bg-[#f3f3f3]'
        role='button'
        tabIndex={0}
        onClick={(e) => setIsMenuOpen((prev) => !prev)}
      >
        <span
          className='Appkit4-icon icon-horizontal-more-outline'
          aria-hidden='true'
        ></span>
      </div>
      {isMenuOpen && (
        <div
          className='pannel-base w-[5rem] p-1 !bg-white absolute -right-2 z-10'
          ref={menuRef}
          style={{ bottom: `-${menuHeight + 4}px` }}
        >
          {COMMENT_MENU_LIST.map((item, idx) => (
            <DropdownMenuItem
              key={idx}
              value={item.value}
              label={item.value}
              data={item.value}
              className='justify-center items-center text-sm h-[1.7rem] bg-white'
              onSelect={(e) => {
                onMenuClick(e);
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
}
