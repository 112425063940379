import * as React from "react";

interface FileDownloadButtonPropsType {
  data: string[];
  className?: string;
}

export const FileDownloadButton: React.FC<FileDownloadButtonPropsType> = (
  props
) => {
  const { data, className } = props;
  return (
    <>
      {data.map((item, idx) => (
        <button
          className={`block underline underline-offset-4 text-primary-200 hover:text-primary-400 transition-all ${className}`}
          onClick={() => {
            //TODO: file download function 추가
          }}
          key={idx}
        >
          <span className="Appkit4-icon icon-paperclip-outline text-primary-400" />
          {item}
        </button>
      ))}
    </>
  );
};
