//component import

import { Button, ButtonGroup } from '@appkit4/react-components';

export interface ButtonSetProps {
  buttons: {
    icon?: string;
    kind?: string;
    onClick?: (
      event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>
    ) => void;
    role?: string;
    txt?: string;
    disabled?: boolean;
  }[];
  className?: string;
}

export const ButtonSet: React.FC<ButtonSetProps> = ({ buttons, className }) => {
  return (
    <ButtonGroup className={className}>
      {buttons.map((item) => (
        <Button
          key={item.txt}
          role={item.role}
          kind={item.kind}
          onClick={item.onClick}
          icon={item.icon}
          disabled={item.disabled}
        >
          {item.txt}
        </Button>
      ))}
    </ButtonGroup>
  );
};
