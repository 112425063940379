import { INITIAL_COMMENT, INITIAL_POST_DETAIL } from 'const';
import { NoticeResponseKeyType, PostDetailStoreType } from 'types';
import {
  axiosClient,
  findCommentById,
  getCommentList,
  getCreateCommentBody,
  getCreatePostBody,
  getCreatePostQnABody,
  getDeleteCommentBody,
  getDeleteSinglePostBody,
  getErrorStatus,
  getUpdateCommentBody,
} from 'utils';

import { create } from 'zustand';

const usePostDetail = create<PostDetailStoreType>((set, get) => ({
  detail: INITIAL_POST_DETAIL,
  comments: [],
  newComment: INITIAL_COMMENT,
  newReply: INITIAL_COMMENT,
  selectedComment: INITIAL_COMMENT,
  detailActions: {
    resetDetail: () =>
      set(() => ({
        detail: INITIAL_POST_DETAIL,
      })),
    setDetail: (newDetail) =>
      set(() => ({
        detail: newDetail,
      })),
    setDetailItem: (key: NoticeResponseKeyType, value: any) =>
      set((prev) => ({
        detail: { ...prev.detail, [key]: value },
      })),
  },
  commentActions: {
    setComment: (newComment) => set(() => ({ comments: newComment })),
    setNewComment: (key, value) =>
      set((prev) => ({
        newComment: { ...prev.newComment, [key]: value },
      })),
    setNewReply: (key, value) =>
      set((prev) => ({
        newReply: { ...prev.newReply, [key]: value },
      })),
    setCommentItem: (id, key, value) =>
      set((prev) => ({
        comments: prev.comments.map((item, index) =>
          index === id ? { ...item, [key]: value } : item
        ),
      })),
    setSelectedComment: (id) =>
      set((prev) => ({
        selectedComment: findCommentById(id, prev.comments),
      })),
    updateSelectedComment: (key, value) =>
      set((prev) => ({
        selectedComment: { ...prev.selectedComment, [key]: value },
      })),
  },
  detailFetch: {
    createPost: async (endpoint) => {
      const body =
        endpoint === 'qna' || endpoint === 'faq'
          ? getCreatePostQnABody(get().detail)
          : getCreatePostBody(get().detail);
      const url = `${endpoint}/create/`;

      try {
        const apiClient = await axiosClient('form');
        const createRes = await apiClient.post(url, body);

        return createRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    updatePost: async (endpoint, id) => {
      const body =
        endpoint === 'qna' || endpoint === 'faq'
          ? getCreatePostQnABody(get().detail)
          : getCreatePostBody(get().detail);
      const url = `${endpoint}/${id}/edit/`;

      try {
        const apiClient = await axiosClient('form');
        const createRes = await apiClient.post(url, body);

        return createRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    getPost: async (endpoint, id) => {
      const permissions = sessionStorage.getItem('permissions');
      const url = `${endpoint}/${id}/?permissions=${permissions}`;
      const apiClient = await axiosClient('text');

      try {
        const createRes = await apiClient.get(url);
        const data = createRes.data;
        const comment = data.comments ? getCommentList(data.comments) : [];

        set(() => ({
          detail: data,
          comments: comment,
        }));

        return createRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    deleteSinglePost: async (endpoint, postId) => {
      const body = getDeleteSinglePostBody();
      const url = `${endpoint}/${postId}/delete/`;

      try {
        const apiClient = await axiosClient('form');
        const res = await apiClient.post(url, body);
        return res.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
  },
  commentFetch: {
    createComment: async (postId, parentId, target) => {
      const url = `qna/${postId}/create-comment/`;
      const content = target === 'comment' ? get().newComment : get().newReply;
      const body = getCreateCommentBody(content, postId, parentId);
      const apiClient = await axiosClient('form');

      try {
        const createRes = await apiClient.post(url, body);
        createRes.status === 200 && (await get().detailFetch.getPost('qna', postId));

        return createRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    updateComment: async (postId) => {
      const url = `qna/${postId}/update-comment/`;
      const body = getUpdateCommentBody(get().selectedComment);

      try {
        const apiClient = await axiosClient('form');
        const updateRes = await apiClient.post(url, body);
        updateRes.status === 200 && (await get().detailFetch.getPost('qna', postId));

        return updateRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
    deleteComment: async (postId) => {
      const url = `qna/${postId}/delete-comment/`;
      const body = getDeleteCommentBody(get().selectedComment.id);
      const apiClient = await axiosClient('form');

      try {
        const updateRes = await apiClient.post(url, body);
        updateRes.status === 200 && (await get().detailFetch.getPost('qna', postId));

        return updateRes.status;
      } catch (e) {
        const status = getErrorStatus(e);
        return status;
      }
    },
  },
}));

// export detail store
export const useDetail = () => usePostDetail((state) => state.detail);
export const usePostDetailActions = () => usePostDetail((state) => state.detailActions);
export const usePostDetailFetch = () => usePostDetail((state) => state.detailFetch);

// export comment store
export const useComment = () => usePostDetail((state) => state.comments);
export const useNewComment = () => usePostDetail((state) => state.newComment);
export const useNewReply = () => usePostDetail((state) => state.newReply);
export const useSelectedComment = () => usePostDetail((state) => state.selectedComment);
export const useCommentActions = () => usePostDetail((state) => state.commentActions);
export const useCommentFetch = () => usePostDetail((state) => state.commentFetch);
