import { CommentForm, EditableCommentItem } from 'components/comment';
import {
  useComment,
  useCommentActions,
  useCommentFetch,
  useErrorActionsStore,
  useNewComment,
  useNewReply,
} from 'store';

import { ACCEPT_FILE_TYPES } from 'const';
import { FeedsComments } from '@appkit4/react-components';
import { PostDetail } from 'components/post';
import React from 'react';
import { commentListItem } from 'types';
import { useParams } from 'react-router';

export default function QnADetail() {
  const params = useParams();
  const [visibleReplyIdx, setVisibleReplyIdx] = React.useState('');

  //store
  const comment = useComment();
  const newReply = useNewReply();
  const newComment = useNewComment();
  const { setNewComment, setNewReply } = useCommentActions();
  const { createComment, updateComment, deleteComment } = useCommentFetch();
  const { setError } = useErrorActionsStore();

  // 현재 게시글의 id
  const qnaId = params.id ? params.id : '0';

  // 대댓글 open event
  const openReply = (list: commentListItem, listIndex: string) => {
    // 선택된 index와 visibleReplyIdx가 같으면 열린 대댓글 접기
    if (visibleReplyIdx === listIndex) {
      setVisibleReplyIdx('');
      return;
    }

    setVisibleReplyIdx(listIndex);
  };

  const onCreateCommentClick = async (parentId: number | null, target: 'comment' | 'reply') => {
    const resStatus = await createComment(qnaId, parentId, target);
    resStatus !== 200 && setError(true, resStatus);
  };

  const onClickUpdateComment = async () => {
    const resStatus = await updateComment(qnaId);
    resStatus !== 200 && setError(true, resStatus);
  };

  const onClickdeleteComment = async () => {
    const resStatus = await deleteComment(qnaId);
    resStatus !== 200 && setError(true, resStatus);
  };

  return (
    <>
      <PostDetail page='qna' />
      {/* comment 신규 등록 */}
      <CommentForm
        replycontent={newComment.content}
        onAddClick={() => onCreateCommentClick(null, 'comment')}
        showAttachment={true}
        maxLength={420}
        onCommentContentChange={setNewComment}
        accept={ACCEPT_FILE_TYPES}
        className='pannel-base bg-white p-6 mt-6'
      />
      {/* comment list */}
      {comment.length !== 0 && (
        <FeedsComments
          type={'comments'}
          className='mt-6'
          renderCommentList={() => {
            return comment?.map((item, index) => (
              <div key={index.toString()}>
                {/* 댓글 */}
                <EditableCommentItem
                  item={item}
                  idx={index}
                  openReply={openReply}
                  updateCommentHandler={() => onClickUpdateComment()}
                  onDeleteComment={() => onClickdeleteComment()}
                />
                {/* 대댓글 */}
                {item.replyList && visibleReplyIdx === index.toString() && (
                  <FeedsComments
                    type={'comments'}
                    className='ml-8 !pt-0 !shadow-none !bg-transparent'
                    renderCommentList={() => {
                      return item.replyList.length === 0 ? (
                        <CommentForm
                          replycontent={newReply.content}
                          showAttachment={true}
                          maxLength={420}
                          onCommentContentChange={setNewReply}
                          hasTitle={false}
                          placeholderWithoutContent='Write a reply...'
                          placeholderWithContent='Your reply'
                          title='Add a new reply'
                          className='w-full pr-3'
                          accept={ACCEPT_FILE_TYPES}
                          onAddClick={() => onCreateCommentClick(item.id, 'reply')}
                        />
                      ) : (
                        item.replyList.map((replyItem, replyIndex) => (
                          <>
                            <EditableCommentItem
                              type='reply'
                              item={replyItem}
                              idx={replyIndex}
                              key={replyIndex.toString()}
                              updateCommentHandler={() => onClickUpdateComment()}
                              onDeleteComment={() => onClickdeleteComment()}
                            />

                            {/* 대댓글 등록 */}
                            {replyIndex === item.replyList.length - 1 && (
                              <CommentForm
                                replycontent={newReply.content}
                                showAttachment={true}
                                maxLength={420}
                                onCommentContentChange={setNewReply}
                                hasTitle={false}
                                placeholderWithoutContent='Write a reply...'
                                placeholderWithContent='Your reply'
                                title='Add a new reply'
                                className='w-full pr-3'
                                accept={ACCEPT_FILE_TYPES}
                                onAddClick={() => onCreateCommentClick(item.id, 'reply')}
                              />
                            )}
                          </>
                        ))
                      );
                    }}
                  />
                )}
              </div>
            ));
          }}
        />
      )}
    </>
  );
}
