import {
  ADMIN,
  AUTH,
  AUTHERROR,
  DSD_WIZARD,
  FAQ,
  HOME,
  NOTICE,
  PATH_LIST,
  QNA,
  REFERENCE,
  UNAUTHORIZED,
} from 'const';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import Admin from 'components/pages/admin/Admin';
import AdminEdit from 'components/pages/admin/AdminEdit';
import App from './App';
import Auth from 'components/pages/auth/Auth';
import AuthError from 'components/pages/auth/AuthError';
import FAQDetail from './components/pages/faq/FAQDetail';
import FAQEditor from './components/pages/faq/FAQEditor';
import FAQPage from './components/pages/faq/FAQPage';
import Home from './components/pages/home/Home';
import Manual from 'components/pages/manual/Manual';
import ManualEdit from 'components/pages/manual/ManualEdit';
import Notification from './components/pages/notification/Notification';
import NotificationDetail from './components/pages/notification/NotificationDetail';
import NotificationEditor from './components/pages/notification/NotificationEditor';
import QnA from './components/pages/qna/QnA';
import QnADetail from './components/pages/qna/QnADetail';
import QnAEditor from './components/pages/qna/QnAEditor';
import Reference from './components/pages/reference/Reference';
import ReferenceDetail from './components/pages/reference/ReferenceDetail';
import ReferenceEditor from './components/pages/reference/ReferenceEditor';
import Unauthorized from 'components/pages/auth/Unauthorized';

export const router = createBrowserRouter([
  {
    path: PATH_LIST[AUTHERROR],
    element: <AuthError />,
  },
  {
    path: PATH_LIST[AUTH],
    element: <Auth />,
  },
  {
    path: PATH_LIST[UNAUTHORIZED],
    element: <Unauthorized />,
  },
  {
    element: <App />,
    children: [
      {
        path: PATH_LIST[HOME],
        element: <Home />,
      },
      {
        path: PATH_LIST[FAQ],
        element: <FAQPage />,
      },
      {
        path: `${PATH_LIST[FAQ]}/:id`,
        element: <FAQDetail />,
      },
      {
        path: `${PATH_LIST[FAQ]}/:id/edit`,
        element: <FAQEditor />,
      },
      {
        path: `${PATH_LIST[FAQ]}/create`,
        element: <FAQEditor />,
      },
      {
        path: PATH_LIST[NOTICE],
        element: <Notification />,
      },
      {
        path: `${PATH_LIST[NOTICE]}/:id`,
        element: <NotificationDetail />,
      },
      {
        path: `${PATH_LIST[NOTICE]}/:id/edit`,
        element: <NotificationEditor />,
      },
      {
        path: `${PATH_LIST[NOTICE]}/create`,
        element: <NotificationEditor />,
      },
      {
        path: PATH_LIST[QNA],
        element: <QnA />,
      },
      {
        path: `${PATH_LIST[QNA]}/:id`,
        element: <QnADetail />,
      },
      {
        path: `${PATH_LIST[QNA]}/:id/edit`,
        element: <QnAEditor />,
      },
      {
        path: `${PATH_LIST[QNA]}/create`,
        element: <QnAEditor />,
      },
      {
        path: PATH_LIST[REFERENCE],
        element: <Reference />,
      },
      {
        path: `${PATH_LIST[REFERENCE]}/:id`,
        element: <ReferenceDetail />,
      },
      {
        path: `${PATH_LIST[REFERENCE]}/:id/edit`,
        element: <ReferenceEditor />,
      },
      {
        path: `${PATH_LIST[REFERENCE]}/create`,
        element: <ReferenceEditor />,
      },
      {
        path: `${PATH_LIST[ADMIN]}`,
        element: <Admin />,
      },
      {
        path: `${PATH_LIST[ADMIN]}/edit`,
        element: <AdminEdit />,
      },
      {
        path: `${PATH_LIST[DSD_WIZARD]}`,
        element: <Manual />,
      },
      {
        path: `${PATH_LIST[DSD_WIZARD]}/edit`,
        element: <ManualEdit />,
      },
      // 정의되지 않은 경로에 대한 NotFound 처리
      {
        path: '*',
        element: (
          <Navigate
            to={PATH_LIST[HOME]}
            replace
          />
        ),
      },
    ],
  },
]);
