import { ADMIN, FAQ, HOME, NOTICE, QNA, REFERENCE } from './path';

import { NavigationItem } from '@appkit4/react-components';
import { PrevBoard } from 'types';

//global
export const PRODUCT_NAME = 'Robotic Application Portal';

export const APP_LIST = ['Robotic PA', 'Robotic XBRL', '사업보고서 작성 플랫폼'];

export const PERMISSION_LIST = ['Super User', 'PwC Manager', 'User'];

export const FILTER_LIST = [
  { label: APP_LIST[0], value: APP_LIST[0] },
  { label: APP_LIST[1], value: APP_LIST[1] },
  { label: APP_LIST[2], value: APP_LIST[2] },
];

export const TABLE_HEADER_LIST_QNA = [
  '번호',
  '카테고리',
  '어플리케이션',
  '제목',
  '작성자',
  '작성일시',
  '상태',
];

export const TABLE_HEADER_LIST = ['번호', '어플리케이션', '제목', '작성자', '조회', '작성일시'];

export const MODAL_CONTENTS = {
  create: {
    title: '게시글을 등록하시겠습니까?',
    text: '확인 버튼을 클릭하면 작성하신 게시글이 즉시 게시됩니다. \n게시 이후에는 게시글을 클릭하여 편집 및 수정이 가능합니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  cancelCreate: {
    title: '게시글을 등록을 취소하시겠습니까?',
    text: '확인 버튼을 클릭하면 작성된 내용이 모두 삭제됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  deleteAll: {
    title: '선택한 게시글을 삭제하시겠습니까?',
    text: '선택된 게시글 : Notification 1, Notification 2 ',
  },
  deleteComment: {
    title: '선택한 댓글을 삭제하시겠습니까?',
    text: '확인 버튼을 클릭하면 작성된 내용이 모두 삭제됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  delete: {
    title: '게시글을 삭제하시겠습니까?',
    text: '확인 버튼을 클릭하면 게시글이 삭제됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  deleteAccount: {
    title: '계정을 삭제하시겠습니까?',
    text: '확인 버튼을 클릭하면 선택한 계정이 모두 삭제됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  edit: {
    title: '게시글을 수정하시겠습니까?',
    text: '확인 버튼을 클릭하면 변경된 수정사항이 즉시 게시글에 반영됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
  cancelEdit: {
    title: '게시글을 수정을 취소하시겠습니까?',
    text: '확인 버튼을 클릭하면 변경된 수정 사항이 모두 삭제됩니다. \n계속 진행하시려면 확인 버튼을 클릭해주세요.',
  },
};

export const ERROR_MODAL_TITLE: Record<number, string> = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  408: 'Request Timeout',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  0: 'Somthing went worng',
};

export const ERROR_MODAL_CONTENT: Record<number | string, string> = {
  400: '잘못된 요청입니다.\n입력값을 다시 확인해 주세요.',
  401: '접근 권한이 없습니다.\n로그인 후 다시 시도해 주세요.',
  403: '권한이 없습니다.\n접근 권한을 확인하세요.',
  404: '요청하신 페이지를 찾을 수 없습니다.',
  408: '요청 시간이 초과되었습니다.\n다시 시도해 주세요.',
  429: '요청이 너무 많습니다.\n잠시 후 다시 시도하세요.',
  500: '서버에 문제가 발생했습니다.\n잠시 후 다시 시도해 주세요.',
  502: '연결에 문제가 발생했습니다.\n잠시 후 다시 시도하세요.',
  503: '서버가 응답하지 않습니다.\n잠시 후 다시 시도해 주세요.',
  504: '서버 응답 시간이 초과되었습니다.\nc잠시 후 다시 시도해 주세요.',
  0: '일시적인 문제로 요청을 완료할 수 없습니다.\n새로 고침 후 다시 시도해 주세요.\n문제가 지속되면 문의게시판에 질문을 남겨 주세요.',
};

export const COMMENT_MENU_LIST = [
  { label: 'edit', value: '수정' },
  { label: 'delete', value: '삭제' },
];

//layout
export const NAV_LIST: NavigationItem[] = [
  {
    name: HOME,
    prefixIcon: 'home-outline ',
    prefixCollapsedIcon: 'home-fill ',
  },
  {
    name: NOTICE,
    prefixIcon: 'notification-outline ',
    prefixCollapsedIcon: 'notification-fill ',
  },
  {
    name: REFERENCE,
    prefixIcon: 'folder-closed-outline ',
    prefixCollapsedIcon: 'folder-closed-fill ',
  },
  {
    name: '고객센터',
    prefixIcon: 'ask-question-outline ',
    suffixIcon: 'down-chevron',
    prefixCollapsedIcon: 'ask-question-fill ',
    children: [
      {
        name: FAQ,
      },
      {
        name: QNA,
      },
    ],
  },
  {
    name: '삼일회계법인 공식 홈페이지',
    prefixIcon: 'building-outline ',
    prefixCollapsedIcon: 'building-fill ',
    divider: true,
  },
];

export const ADMIN_NAV_ITEM = {
  name: ADMIN,
  prefixIcon: 'account-box-outline ',
  prefixCollapsedIcon: 'account-box-fill ',
};

export const FOOTER_CONTENT =
  '© 2024 PwC. All rights reserved. PwC refers to the US member firm of the PwC network or one of its subsidiaries or affiliates.';

export const FOOTER_LINK_LIST = [
  {
    name: '개인정보 처리방침',
    href: 'https://www.pwc.com/kr/ko/information.html',
    target: '_blank',
  },
  {
    name: 'Legal Disclaimer',
    href: 'https://www.pwc.com/kr/ko/information/legal-disclaimer.html',
    target: '_blank',
  },
  {
    name: 'Cookie policy',
    href: 'https://www.pwc.com/kr/ko/information/cookie-policy.html',
    target: '_blank',
  },
];

// home
export const getBannerTit = (name: string) => {
  return `Welcome to ${PRODUCT_NAME}, ${name}!`;
};
export const BANNER_TXT =
  'Application에 관련된 서비스를 제공하는 원스톱 플랫폼입니다.\n최신 소식, 유용한 자료, 신속한 고객 지원을 통해 최고의 경험을 제공해드립니다.';
export const APP_INTRODUCTION =
  'Robotic Application은 기업의 업무효율성을 제고할 수 있는 다양한 Application을 개발하여 제공하는 서비스입니다.\n그동안 당연하게 수행되었던 일상적인 업무에 삼일회계법인의 전문성과 기술력이 집약된 새로운 형태의 솔루션을 만나보실 수 있습니다.';
export const APP_INTRODUCTION_LIST = [
  {
    id: 1,
    name: 'Robotic PA',
    subtit: '재무제표 작성 효율화 Tool',
    caption:
      'Smart Reviewer와 DSD Wizard를 통해 기존의 재무제표와 주석의 작성부터 검토, 비교까지 업무 전반의 효율성과 실질적 Risk 관리를 도와주는 서비스입니다. ',
  },
  {
    id: 2,
    name: '사업보고서 작성 플랫폼',
    subtit: '사업보고서 작성 효율화 Tool',
    caption:
      '사업보고서 Data의 집계와 검토 및 공시 DSD의 자동 작성 등 일련의 프로세스를 효율적이고 효과적으로 개선 가능한 플랫폼 구축 서비스입니다. 인적자원을 효율적으로 활용하고 사업보고서의 공시 정확성 강화를 목적으로 합니다. ',
  },
  {
    id: 3,
    name: 'Robotic XBRL',
    subtit: 'XBRL 작성 효율화 Tool',
    caption:
      'Smart Reviewer와 DSD Wizard를 통해 기존의 재무제표와 주석의 작성부터 검토, 비교까지 업무 전반의 효율성과 실질적 Risk 관리를 도와주는 서비스입니다.',
  },
];
export const CS_CENTER_BUTTONS = [
  { id: 1, name: QNA, icon: 'comment' },
  { id: 2, name: FAQ, icon: 'help-question' },
];

export const HOME_PREVIEW_BOARD_LIST: PrevBoard[] = [
  { id: 1, title: NOTICE, data: [] },
  { id: 2, title: REFERENCE, data: [] },
];

//권한관리
export const TABLE_HEADER_LIST_ADMIN = ['회사명', '이름', '이메일', '권한', '어플리케이션'];
