import { useRef, useState } from 'react';

import { useEffect } from 'react';

export function useDebounce(callback: (value: string) => void, delay: number) {
  const [inputValue, setInputValue] = useState<string>('');
  const lastValueRef = useRef<string>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      if (lastValueRef.current !== inputValue) {
        lastValueRef.current = inputValue;
        callback(inputValue);
      }
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue, delay, callback]);

  const handleInputChange = (value: string) => {
    setInputValue(value);
  };

  return handleInputChange;
}
