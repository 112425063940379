import { Button, Modal } from '@appkit4/react-components';

import { MODAL_CONTENTS } from 'const';
import { ModalVariantType } from 'types';
import React from 'react';

// component import

export interface ConfirmModalProps {
  visible: boolean;
  variant: ModalVariantType;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
}

// 실행 확인 modal
export const ConfirmModal: React.FC<ConfirmModalProps> = ({
  visible,
  variant,
  onCancel,
  onConfirm,
  onClose,
}) => {
  // 실행 취소
  const cancelHandler = () => {
    onCancel && onCancel();
  };

  // 실행 확인
  const confirmHandler = () => {
    onConfirm && onConfirm();
  };

  // modal 닫기
  const closeHandler = () => {
    onClose && onClose();
  };
  return (
    <Modal
      visible={visible}
      title={MODAL_CONTENTS[variant].title}
      ariaLabel={'취소'}
      onCancel={closeHandler}
      modalStyle={{ width: '33.75rem' }}
      footerStyle={{
        paddingTop: '8px',
        marginTop: '-8px',
        minHeight: '64px',
      }}
      footer={
        <>
          <Button
            onClick={cancelHandler}
            kind='secondary'
          >
            취소
          </Button>
          <Button onClick={confirmHandler}>확인</Button>
        </>
      }
      bodyStyle={{ minHeight: '92px' }}
    >
      <div className='w-full whitespace-break-spaces'>{MODAL_CONTENTS[variant].text}</div>
    </Modal>
  );
};
