import { Button, Modal } from '@appkit4/react-components';
import { useErrorActionsStore, useErrorStore } from 'store';

import { ERROR_MODAL_TITLE } from 'const';

export default function ErrorModal() {
  const error = useErrorStore();
  const { setError } = useErrorActionsStore();

  const onConfirm = () => {
    setError(false, 0);
  };

  return error.status ? (
    <Modal
      visible={error.status}
      title={ERROR_MODAL_TITLE[error.code] || ERROR_MODAL_TITLE[0]}
      ariaLabel={'취소'}
      onCancel={onConfirm}
      modalStyle={{ width: '33.75rem' }}
      footerStyle={{
        paddingTop: '8px',
        marginTop: '-8px',
        minHeight: '64px',
      }}
      footer={<Button onClick={onConfirm}>확인</Button>}
      bodyStyle={{ minHeight: '92px' }}
    >
      <p className='mb-8 text-xl'>{error.message}</p>
    </Modal>
  ) : (
    <></>
  );
}
